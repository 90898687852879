<div class="modal-header">
  <h6 class="h7 mb-0">{{ 'modals.edit_patient.title' | translate }}</h6>

  <button type="button" class="btn-close" id="edit-patient-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
  </button>
</div>

<div class="modal-body">
  <div class="is-loading" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <form *ngIf="!isLoading" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- Profile picture -->
    <div class="form-width-limit-element">
      <label for="avatar-input" class="small mb-1">{{ 'form.labels.profile_picture' | translate }}</label>

      <div class="control-profile-picture mb-3">
        <app-avatar-input id="avatar-input" formControlName="profile_picture"></app-avatar-input>
      </div>
    </div>

    <!-- Gender -->
    <div class="form-group mb-n2 form-no-width-limit-element">
      <label class="required">{{ 'form.labels.gender' | translate }}</label>

      <div class="form-checks-inline">
        <div class="form-check">
          <input type="radio" class="form-check-input" id="radio_details_form_sex_male" formControlName="gender" value="MALE">
          <label class="form-check-label" for="radio_details_form_sex_male">{{ 'form.labels.male' | translate }}</label>
        </div>

        <div class="form-check">
          <input type="radio" class="form-check-input" id="radio_details_form_sex_female" formControlName="gender" value="FEMALE">
          <label class="form-check-label" for="radio_details_form_sex_female">{{ 'form.labels.female' | translate }}</label>
        </div>

        <div class="form-check">
          <input type="radio" class="form-check-input" id="radio_details_form_sex_other" formControlName="gender" value="UNKNOWN">
          <label class="form-check-label" for="radio_details_form_sex_other">{{ 'form.labels.other' | translate }}</label>
        </div>
      </div>
    </div>

    <!-- First name -->
    <div class="form-group">
      <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>

      <input type="text" id="inputFirstName" class="form-control w-100"
        placeholder="" formControlName="first_name">
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group">
      <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>

      <input type="text" id="inputLastName" class="form-control w-100"
        placeholder="" formControlName="last_name">

      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Preferred name -->
    <div class="form-group">
      <label for="inputPreferredName">{{ 'form.labels.preferred_name' | translate }}</label>

      <input type="text" id="inputPreferredName" class="form-control w-100"
        placeholder="" formControlName="preferred_name">

      <app-feedback-field [field]="form.get('preferred_name')"></app-feedback-field>
    </div>

    <!-- Country -->
    <div class="form-group required">
      <app-select [label]="('form.labels.country' | translate)" [clearable]="false" [searchable]="true"
        [hideSelected]="false" [multiple]="false" [items]="countryOptions" [bindValue]="'value'"
        [autoSortOnLabel]="true" formControlName="country" [asFilter]="false" [showCheckboxes]="false"></app-select>

      <app-feedback-field [field]="form.get('country')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select [label]="('form.labels.language' | translate)" [clearable]="false" [searchable]="true"
        [hideSelected]="false" [multiple]="false" [items]="languageOptions" [bindValue]="'value'"
        [autoSortOnLabel]="true" [selectedItemsOnTop]="false" formControlName="language" [asFilter]="false"
        [showCheckboxes]="false"></app-select>

      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Date of birth -->
    <div class="form-group">
      <label class="required" for="inputBirth">{{ 'form.labels.date_of_birth' | translate }}</label>

      <input type="text" id="inputBirth" class="form-control w-100"
        placeholder="" bsDatepicker formControlName="date_of_birth">

      <app-feedback-field [field]="form.get('date_of_birth')"></app-feedback-field>
    </div>

    <hr>

    <!-- EMR -->
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label for="inputEMR">{{ 'modals.add_patient_wizard.emr' | translate }}</label>

        <p class="extra-small text-muted m-0"
           *ngIf="!(validationVisible && !form?.controls?.electronic_medical_record?.value)">
          {{( (form?.controls?.electronic_medical_record?.value?.length || '0') | translateNumber )}}/{{emrMaxLength |
          translateNumber}}
        </p>
      </div>

      <input type="text" id="inputEMR" class="form-control w-100"
        placeholder="" formControlName="electronic_medical_record"
        [maxlength]="emrMaxLength">

      <app-feedback-field [field]="form.get('electronic_medical_record')"></app-feedback-field>
    </div>

    <hr>

    <!-- Email address (if patient is not fully onboarded yet) -->
    <div class="row">
      <div class="col">
        <div class="form-group mb-0">
          <label for="inputEmail">{{ 'form.labels.email' | translate }}</label>

          <div class="d-flex align-items-center">
            <input type="email" id="inputEmail" class="form-control w-100 m-end-2"
              [placeholder]="('form.labels.email' | translate)" formControlName="email">

            <button type="button" class="btn btn-link m-start-auto" *ngIf="patient?.email_change_allowed" (click)="showEditEmailModal()" id="edit-patient-email">{{
                'modals.edit_patient.edit_email' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-patient-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate
      }}</button>

    <button class="btn btn-primary m-start-2" id="edit-patient-modal-confirm" [ngClass]="{'loader': isSaving}"
      (click)="handleConfirm()">

      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
