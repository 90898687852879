
<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">

      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.administration.hcps.hcps' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <a href="" class="btn btn-primary" id="admin-hcps-add-hcp"
            (click)="onShowAddHcpModal($event)">{{ 'pages.default.administration.hcps.add_hcp' | translate }}</a>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="body-content">
  <div class="container pb-4">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">

        <div class="filters-group">

          <div class="form-group form-group-search">
            <label for="searchHcpLastName">{{ 'form.labels.hcp_last_name' | translate }}</label>
            <input
              type="text"
              id="searchHcpLastName"
              class="form-control form-control-sm form-control-search"
              [placeholder]="'form.labels.search' | translate"
              [(ngModel)]="filters.hcpLastName"
              (ngModelChange)="searchHcpLastNameEvent.next($event)"
              required
            />
          </div>

          <div class="divider d-none"></div>

          <div class="form-group d-none">
            <app-select
              id="admin-hcp-select-function"
              class=""
              [selectClass]="'ng-select-sm'"
              [label]="('form.labels.function' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="true"
              [items]="functions"

              [(ngModel)]="functionFilter"
              (ngModelChange)="onFunctionFilterChange($event)"

              [asFilter]="true"
              [showCheckboxes]="true"
            ></app-select>
          </div>

          <div class="divider" *ngIf="filtersAreSet"></div>

          <button type="button" class="btn btn-link text-info py-0" id="admin-hcps-reset-filters" *ngIf="filtersAreSet" (click)="onResetFilters()">{{ 'action.reset' | translate }}</button>
        </div>

      </div>

      <div class="col col-auto">
        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="dropdown-btn-sort"
              [label]="'general.sort_by' | translate"
              [selectClass]="'ng-select-sm'"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="sortingOptions"
              [bindValue]="'value'"
              [(ngModel)]="sorting"

              (ngModelChange)="onChangeSort()"

              [asFilter]="false"
            ></app-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div *ngFor="let hcp of hcps; let index = index;">

      <div id="hcp-{{index}}" class="single-item-block block-compact mb-2" [ngClass]="{'block-muted': hcp?.status !== 'ACTIVE'}">
        <div class="block-wrapper p-end-0">
          <app-avatar class="" [profilePicture]="hcp?.profile_picture"></app-avatar>
        </div>

        <div class="row d-flex align-items-center w-100">
          <div class="col-3">
            <div class="block-wrapper">
              <p class="label text-truncate">{{hcp.getFullName()}}</p>
              <p class="value text-truncate font-weight-medium">{{ hcp.job_title }}</p>
            </div>
          </div>
          <div class="col-3">
            <div class="block-wrapper">
              <p class="text-dark text-truncate" *ngIf="scopeService.getCurrentHospitalBasic()?.name">{{scopeService.getCurrentHospitalBasic()?.name}}</p>
              <p class="text-dark text-truncate" *ngIf="!scopeService.getCurrentHospitalBasic()?.name">
                {{'pages.default.administration.hcps.unknown_hospital' | translate}}
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class="block-wrapper block-meta">
              <ng-container *ngIf="(hcp.status === 'ACTIVE'); else nonActive">

                <a href="mailto:{{hcp.email}}" class="meta-item" [id]="'admin-hcps-email-' + hcp.uid">
                  <span [inlineSVG]="'./assets/svg/envelope-outline-24.svg'" class="icon"></span>
                  <span>{{hcp.email}}</span>
                </a>

                <p *ngIf="hcp.phone_number?.number" class="meta-item">
                  <span [inlineSVG]="'./assets/svg/mobile-device-24.svg'" class="icon"></span>
                  <span>{{getPhonePrefix(hcp.phone_number)}} {{hcp.phone_number?.number}}</span>
                </p>

              </ng-container>
              <ng-template #nonActive>
                <p id="hcp-{{index}}-non-active" class="meta-item">
                  <span [inlineSVG]="'./assets/svg/' + getIconForStatus(hcp.status) + '.svg'" class="icon small rtl-mirrored-inline-svg m-end-1 align-middle"></span>
                  <span class="align-middle">{{'pages.default.administration.hcps.status.' + hcp.status | translate}}</span>
                </p>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="block-btn-wrapper m-end-1">
          <a href="" (click)="onShowEditHcpModal($event, hcp)" class="block-action btn btn-lg btn-tertiary btn-icon" [id]="'admin-hcps-edit-hcp-' + hcp.uid">
            <span [inlineSVG]="'./assets/svg/edit-outline-24.svg'"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
