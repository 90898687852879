<div class="modal-header">
  <h6 class="lead fw-semibold">{{ appointment?.title }}</h6>
  <button type="button" id="appointment-modal-close" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div class="info-box info-box-warning no-icon mb-3" *ngIf="appointment?.hasCancelledState()">
    <p class="info-box-title m-0">{{ 'modals.appointment.appointment_cancelled' | translate }}</p>
  </div>

  <div class="mb-2">
    <p class="icon-meta-info" *ngIf="appointment?.start_date && !appointment?.date">
      <span class="icon" [inlineSVG]="'./assets/svg/calendar-24.svg'"></span>
      <span class="text-capitalize">{{ appointment?.start_date | timeZoneDate:'dddd' }}</span>
      <span class="rtl-d-none">,</span>
      <span>
        {{ appointment?.start_date | timeZoneDate }}
        {{ 'modals.appointment.from' | translate }}
        <span [dir]="languageService?.isHebrew ? 'ltr' : ''">{{ appointment?.start_date | time }} - {{ appointment?.end_date | time }}</span>
      </span>
    </p>

    <p class="icon-meta-info" *ngIf="appointment?.date && !appointment?.start_date">
      <span class="icon" [inlineSVG]="'./assets/svg/calendar-24.svg'"></span>
      <span class="text-capitalize">{{ appointment?.date | timeZoneDate:'dddd' }}</span>
      <span class="rtl-d-none">,</span>
      {{ appointment?.date | timeZoneDate }}
      <span class="text-info font-weight-bold m-start-1">
        {{ 'pages.default.calendar.full_day' | translate }}
      </span>
    </p>

    <hr class="my-3"/>
  </div>

  <div class="mb-3" *ngIf="appointment?.location">
    <p class="extra-small text-muted mb-0">{{ 'modals.appointment.location' | translate }}</p>
    <p class="m-0" *ngIf="!appointment.location?.url">{{ appointment.location?.name }}</p>
    <p class="m-0" *ngIf="appointment.location?.url">
      <a [href]="appointment.location.url" id="appointment-modal-location" class="text-info text-icon-link" target="_blank">
        <span class="label">{{ appointment.location?.name }}</span>
        <span class="icon-end" [inlineSVG]="'./assets/svg/external-12.svg'"></span>
      </a>
    </p>
  </div>

  <div class="mb-3" *ngIf="appointment?.patient_invitee">
    <p class="extra-small text-muted mb-1">{{ 'modals.appointment.patient' | translate }}</p>
    <div class="row">
      <div class="col-6">
        <div class="participant-item" >
          <app-avatar [profilePicture]="appointment?.patient_invitee?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ appointment?.patient_invitee?.getFullName() }}</p>
            <p class="description">{{ 'modals.appointment.patient' | translate }}</p>
          </div>
          <div class="status-badge badge-sm" [ngClass]="generalService.statusClass(appointment?.patient_invitee?.status)" *ngIf="canSeeStatus()">
            {{ 'modals.appointment.'+appointment?.patient_invitee?.status.toLocaleLowerCase( )  | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3" *ngIf="appointment?.invitees?.length">
    <p class="extra-small text-muted mb-1">{{ 'modals.appointment.clinical_team' | translate }}</p>
    <div class="row">
      <div class="col-6 mb-2" *ngFor="let participant of appointment?.invitees">
        <div class="participant-item">
          <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ participant.getFullName() }}</p>
            <p class="description">{{ participant?.job_title }}</p>
          </div>
          <div class="status-badge badge-sm" [ngClass]="generalService.statusClass(participant?.status)" *ngIf="canSeeStatus()">
            {{ 'modals.appointment.'+participant?.status.toLocaleLowerCase( ) | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3" *ngIf="appointment?.description">
    <p class="extra-small text-muted mb-1">{{ 'modals.appointment.desc' | translate }}</p>
    <p>{{ appointment?.description }}</p>
  </div>

  <div class="row g-3">
    <div class="col-6">
      <p class="extra-small text-muted mb-1">{{ 'modals.appointment.organised_by' | translate }}</p>

      <div class="participant-inline">
        <app-avatar [profilePicture]="appointment?.organiser?.profile_picture"></app-avatar>
        <p class="title">{{ appointment?.organiser.getFullName() }}</p>
      </div>
    </div>
    <div class="col-6" *ngIf="appointment?.cancelled">
      <p class="extra-small text-muted mb-1">{{ 'modals.appointment.cancelled_by' | translate }}</p>

      <div class="participant-inline">
        <app-avatar [profilePicture]="appointment?.changed_by?.profile_picture"></app-avatar>
        <p class="title">{{ appointment?.changed_by?.getFullName() }}</p>
      </div>
    </div>

    <div class="col-6" *ngIf="!appointment?.cancelled && appointment?.changed_by">
      <p class="extra-small text-muted mb-1">{{ 'modals.appointment.modified_by' | translate }}</p>

      <div class="participant-inline">
        <app-avatar [profilePicture]="appointment?.changed_by?.profile_picture"></app-avatar>
        <p class="title">{{ appointment?.changed_by?.getFullName() }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="canEdit() || canCancel()">
  <p *ngIf="appointment?.hasPendingState() && appointment.status_of_user" class="me-auto fw-medium">{{ 'modals.appointment.are_you_going' | translate }}</p>
  <p *ngIf="appointment?.hasAcceptedState()  && appointment?.status_of_user" class="me-auto fw-medium">{{ 'modals.appointment.you_accepted' | translate }}</p>

  <a *ngIf="appointment?.hasPendingState() && appointment?.status_of_user" (click)="handleNo()" class="btn btn-lg btn-secondary ms-1" [ngClass]="{'loader': showLoaderFor==='DECLINED'}"  id="appointment-modal-handle-no">
    <span class="loader" *ngIf="showLoaderFor==='DECLINED'" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></span>
    <span class="label">{{ 'action.no' | translate }}</span>
  </a>

  <a *ngIf="appointment?.hasPendingState() && appointment?.status_of_user" (click)="handleYes()" class="btn btn-lg btn-primary ms-1" [ngClass]="{'loader': showLoaderFor==='ACCEPTED'}"  id="appointment-modal-handle-yes">
    <span class="loader" *ngIf="showLoaderFor==='ACCEPTED'" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
    <span class="label">{{ 'action.yes' | translate }}</span>
  </a>

  <a *ngIf="canDecline()" (click)="handleDecline()" class="btn btn-lg btn-secondary ms-1" [ngClass]="{'loader': showLoaderFor==='DECLINED'}" id="appointment-modal-handle-decline">
    <span class="loader" *ngIf="showLoaderFor==='DECLINED'" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></span>
    <span class="label">{{ 'modals.appointment.decline_appointment' | translate }}</span>
  </a>

  <a *ngIf="canCancel()" (click)="handleCancel()" class="btn btn-lg btn-secondary ms-1" [ngClass]="{'loader': showLoaderFor==='CANCELLED'}" id="appointment-modal-handle-cancel">
    <span class="loader" *ngIf="showLoaderFor==='CANCELLED'" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></span>
    <span class="label">{{ 'modals.appointment.cancel_appointment' | translate }}</span>
  </a>

  <a *ngIf="canEdit()" (click)="handleEdit()" class="btn btn-lg btn-primary ms-1" id="appointment-modal-handle-edit">
    <span class="label">{{ 'modals.appointment.edit' | translate }}</span>
  </a>

  <!-- TEMP remove for demo
  <a *ngIf="canRemove()" (click)="handleRemove()" class="btn btn-secondary ms-1" id="appointment-modal-handle-remove">
    <span *ngIf="showLoaderFor!=='REMOVED'" class="icon-start" [inlineSVG]="'./assets/svg/delete-24.svg'"></span>
    <em *ngIf="showLoaderFor=='REMOVED'" class="icon-start spinner-border spinner-border-sm"></em>
    <span class="label">{{ 'modals.appointment.remove_from_calendar' | translate }}</span>
  </a>
  -->
</div>
