<div class="user-task-form">
  <div class="evaluation-loader" *ngIf="isEvaluating">
    <app-page-loader></app-page-loader>
  </div>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible, 'is-evaluating': isEvaluating}">

    <!-- Lossless Form Fields - Start -->
    <div class="card card-lg mb-3 mb-xl-4 e2e-lossless-form-fields" *ngIf="getVisibleFormFields().length">
      <div class="card-body">
        @for(field of userTask?.form_fields | sortBy:'asc': 'order'; track field.id) {
          <app-dynamic-input id="no-section-{{field?.id}}" [field]="field" *ngIf="field.visible" [formControl]="form.get(field.id)"
            class="d-block" [ngClass]="{'mt-3': !$first}"></app-dynamic-input>
        }
      </div>
    </div>
    <!-- Lossless Form Fields - End -->
    @for(section of userTask?.sections | sortBy: 'asc' : 'order'; track section.id) {
      <div class="card card-lg mb-3 mb-xl-4" *ngIf="section.visible" [ngClass]="'e2e-section-' + section?.id">
        <div class="card-body">

          <!-- Section title - Start -->
          <p class="lead fw-bold mb-2" *ngIf="section?.title">{{ section?.translationKey | translate | evalTranslation }}</p>
          <!-- Section title - End -->

          <!-- Section description - Start  -->
          <p *ngIf="section?.description" class="mb-2">{{ section.description | translateHelp | translate }}</p>
          <!-- Section description - End  -->

          <!-- Spacer - Start -->
          <div class="pb-1" *ngIf="section?.title || section?.description"></div>
          <div class="mb-2" *ngIf="!section?.title"></div>
          <!-- Spacer - End -->

          @for(field of section?.form_fields | sortBy:'asc': 'order'; track field.id) {
            <app-dynamic-input id="{{section?.id}}_{{field?.id}}" [field]="field" *ngIf="field.visible" [formControl]="form.get(field.id)"
                               class="d-block" [ngClass]="{'mt-3': !$first}"></app-dynamic-input>
          }
        </div>
      </div>
    }
  </form>
</div>

