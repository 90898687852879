<div class="faq-block" [ngClass]="class">
  <div class="heading" *ngIf="showHeading">
    <h6 class="h7">{{ category?.title }}</h6>
  </div>

  <div class="items card">
    <div class="card-body">
      @for (item of category.items; track item.uid) {
        @if (item.uid !== skip) {
          <a href="" (click)="onGoToFaq($event, item, category, section)">
            <span>{{ item.question }}</span>

            <button class="btn btn-tertiary btn-icon btn-lg m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
            </button>
          </a>
        }
      }
    </div>
  </div>
</div>
