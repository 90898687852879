import { TranslationInterface } from '../interfaces/translation.interface';
import { FormFieldValue } from './form-field-value';
import { ValidatorFn, Validators } from '@angular/forms';

export class FormField {
  field_value: FormFieldValue;
  id: string;
  order: number;
  read_only: boolean;
  required: boolean;
  visible: boolean;
  evaluation_required: boolean;
  label: TranslationInterface;
  description: TranslationInterface;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id ? item.id : '';
    this.order = item.order || 0;
    this.required = item.required;
    this.read_only = item.read_only;
    this.visible = item.visible;
    this.evaluation_required = item.evaluation_required;
    this.label = item.label || '';
    this.field_value = new FormFieldValue(item.field_value);
    this.description = item.description;
  }

  get translationKey(): string {
    return `shared.${this.label?.region}.${this.label?.key}`;
  }

  get validators(): ValidatorFn[] {
    const validators = [];

    if (this.required && this.visible) {
      validators.push(Validators.required);
    }

    if (this.field_value.min) {
      validators.push(Validators.min(this.field_value.min));
    }

    if (this.field_value.max) {
      validators.push(Validators.max(this.field_value.max));
    }

    if (this.field_value.min_length) {
      validators.push(Validators.minLength(this.field_value.min_length));
    }

    if (this.field_value.max_length) {
      validators.push(Validators.maxLength(this.field_value.max_length));
    }

    // Not yet in scope
    // if (this.field_value.multi_select_min) {
    //   validators.push(CheckboxValidator.min(this.field_value.multi_select_min));
    // }

    // Not yet in scope
    // if (this.field_value.multi_select_max) {
    //   validators.push(CheckboxValidator.max(this.field_value.multi_select_max));
    // }

    return validators;
  }

  // Special value context
  //
  // When you add id + '_all' or '_none'
  // This is not a normal multiselect value
  // It triggers some custom behaviour.
  // When you select specialAll, it should select all mutliselect options except for specialNone
  // When you select specialNone, it should deselect all other options.

  get specialAll(): string {
    return this.id + '_all';
  }

  get specialNone(): string {
    return this.id + '_none';
  }

  get multiOptions(): Array<string> {
    return this.field_value?.enum_values
      .map(item => item.value) ?? [];
  }

  get multiOptionsWithoutNone(): Array<string> {
    return this.multiOptions.filter(item => item !== this.specialNone);
  }

  get multiOptionsWithoutSpecials(): Array<string> {
    return this.multiOptions.filter(item => item !== this.specialNone && item !== this.specialAll);
  }

  multiValueChange(value: string, multiValue: string[]): string [] {
    const isSpecialAllSelected = value === this.specialAll;
    const isSpecialNoneSelected = value === this.specialNone;

    if (isSpecialAllSelected) {
      if (!multiValue.includes(value)) {
        // Select all
        multiValue = this.multiOptionsWithoutNone;
      } else {
        // Deselect all
        multiValue = [];
      }
    } else if (isSpecialNoneSelected) {
      if (!multiValue.includes(value)) {
        // Select none
        multiValue = [value];
      }
    } else {
      if (multiValue.includes(value)) {
        // Remove from array
        multiValue = multiValue.filter(val => val !== value);

        // Remove all if selected
        if (multiValue.includes(this.specialAll)) {
          multiValue = multiValue.filter(val => val !== this.specialAll);
        }
      } else {
        // Add to array
        multiValue.push(value);

        // Remove none if selected
        if (multiValue.includes(this.specialNone)) {
          multiValue = multiValue.filter(val => val !== this.specialNone);
        }

        // When all normal options are selected, all should also be selected when available
        if (
          this.multiOptions.includes(this.specialAll) &&
          this.arraysHaveSameContents(multiValue, this.multiOptionsWithoutSpecials)) {
          multiValue.push(this.specialAll);
        }
      }
    }

    return multiValue;
  }

  enumChecked(enumValue: string, formValue: string | string[]): boolean {
    if (this.field_value.multi_select_allowed) {
      return formValue?.includes(enumValue);
    } else {
      return enumValue === formValue;
    }
  }

  arraysHaveSameContents(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }
}
