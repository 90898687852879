<div [ngClass]="{'validation-visible': validationVisible}" class="modal-body">
  <button type="button" class="close" id="edit-role-modal-close" data-dismiss="modal" aria-label="Close" (click)="onHandleClose()">
    <span [inlineSVG]="'./assets/svg/close-24.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.edit_role.edit_role_title' | translate }}</h2>

  <div *ngIf="isSaving" class="text-center text-muted py-3">
    <em class="spinner-border spinner-border-sm"></em>
  </div>

  <div *ngIf="!isSaving">
    <ng-select [(ngModel)]="selectedRole"
               [clearable]="false"
               [items]="rolesList"
               [searchable]="false"
               placeholder="{{('modals.edit_role.role') | translate}}"
    >
      <ng-template let-item="item" ng-label-tmp>
        <span *ngIf="item">{{ ('pages.default.patient_detail.roles.' + item) | translate }}</span>
      </ng-template>
      <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
        <span *ngIf="item">{{ ('pages.default.patient_detail.roles.' + item) | translate }}</span>
      </ng-template>
    </ng-select>
  </div>

</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-role-modal-cancel" (click)="onHandleClose()">{{ 'action.cancel' | translate }}</button>
    <button (click)="onHandleSave()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3" id="edit-role-modal-save">
      <span class="loader" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'" *ngIf="isSaving"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
