<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <div class="breadcrumb">
        <a href="" [routerLink]="['/analytics']" class="crumb-link">{{ 'pages.default.analytics.overview.analytics' | translate }}</a>
        <span class="crumb-delimiter rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-right-24.svg'"></span>

        <a href="" *ngIf="breadcrumb.length" (click)="selectModule($event)" class="crumb-link">{{ selectedCareModule?.translationKey | translate }}</a>
        <span *ngIf="breadcrumb.length" class="crumb-delimiter rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-right-24.svg'"></span>
        <span *ngIf="!breadcrumb.length" class="crumb-label">{{ selectedCareModule?.translationKey | translate }}</span>

        <ng-container *ngFor="let phase of breadcrumb" id="breadcrumb">
          <a href="" *ngIf="!currentPhase(phase)"  (click)="selectPhase(phase, $event)" class="crumb-link">{{ phase.title }}</a>
          <span *ngIf="!currentPhase(phase)" class="crumb-delimiter rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-right-24.svg'"></span>
          <span *ngIf="currentPhase(phase)" class="crumb-label">{{ phase.title }}</span>
        </ng-container>
      </div>
    </div>

    <div class="container">
      <h5>{{ 'pages.default.analytics.overview.analytics' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" >
    <div id="zone_patients_overview">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h5>{{ 'pages.default.analytics.overview.title' | translate }}</h5>
          </div>
          <div class="col col-auto" *ngIf="view === 'careModule'">
            <app-select
              id="select-care-module"
              [selectClass]="'ng-select-sm ng-select-align-end'"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="careModules"
              [(ngModel)]="selectedCareModuleUid"
              [bindValue]="'value'"
              (ngModelChange)="onCareModuleChange()"
              [asFilter]="true"
              [showCheckboxes]="true"
            ></app-select>
          </div>
        </div>
      </div>

      <app-dna-kpis [careModuleUid]="selectedCareModuleUid" [phase]="phase"></app-dna-kpis>
    </div>

    <hr class="my-5" />

    <div id="zone_patients_in_progress">
      <h5>{{ 'pages.default.analytics.pathways-overview.patients-in-progress' | translate}}</h5>
      <app-dna-phases [careModuleUid]="selectedCareModuleUid" [phase]="phase" (phaseSelected)="selectPhase($event)"></app-dna-phases>
    </div>

    <hr class="my-5" *ngIf="view === 'phase'" />

    <div id="zone_patients_list" *ngIf="view === 'phase'">
      <app-dna-patients-table [careModuleUid]="selectedCareModuleUid" [phase]="phase"></app-dna-patients-table>
    </div>
  </div>
</div>
