<div class="modal-header">
  <h6 class="h7">{{ 'modals.add_hcp.title' | translate }}</h6>
  <button (click)="onHandleClose()" aria-label="Close" class="btn-close" id="add-hcp-modal-close" data-dismiss="modal" type="button"></button>
</div>

<div class="modal-body">
  <form *ngIf="form" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">

    <!-- Gender -->
    <div class="form-group mb-n1">
      <label class="required">{{ 'form.labels.gender' | translate }}</label>

      <div class="form-checks-inline ">
        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexMale" type="radio" value="MALE">
          <label class="form-check-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexFemale" type="radio" value="FEMALE">
          <label class="form-check-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexOther" type="radio" value="UNKNOWN">
          <label class="form-check-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
        </div>
      </div>

      <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"/>
    </div>

    <!-- First name -->
    <div class="form-group ">
      <label class="required" for="inputFirstName">{{ 'form.labels.first_name' | translate }}</label>
      <input placeholder="" class="form-control w-100" formControlName="first_name"
        id="inputFirstName" type="text">
      <app-feedback-field [field]="form.get('first_name')"/>
    </div>

    <!-- Last name -->
    <div class="form-group ">
      <label class="required" for="inputLastName">{{ 'form.labels.last_name' | translate }}</label>
      <input placeholder="" class="form-control w-100" formControlName="last_name"
        id="inputLastName" type="text">
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Email -->
    <div class="form-group ">
      <label for="inputEmail" class="required">{{ 'form.labels.email_address' | translate }}</label>
      <input placeholder="" class="form-control w-100"
        formControlName="email" id="inputEmail" type="text">
      <app-feedback-field [field]="form.get('email')"></app-feedback-field>
    </div>

    <!-- Phone number -->
    <div class="my-3">
      <div class="row validation-group" formGroupName="phone_number">
        <div class="col-6" [ngClass]="{'order-last': languageService.isHebrew}">
          <div class="form-group required">
            <app-select
              [label]="('form.labels.country_code' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="dialCodeOptions"
              [bindValue]="'value'"

              [autoSortOnLabel]="true"
              [selectedItemsOnTop]="false"

              formControlName="code"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
            <app-feedback-field [field]="form.get('phone_number.code')"></app-feedback-field>

          </div>
        </div>
        <div class="col-6" [ngClass]="{'order-first': languageService.isHebrew}">
          <div class="form-group ">
            <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
            <input placeholder="" class="form-control w-100"
              formControlName="number" id="inputPhone" type="tel">
          </div>
        </div>
      </div>
      <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [label]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Function -->
    <div class="form-group required">
      <app-select
        [label]="('form.labels.function' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"
        formControlName="function"
      ></app-select>
      <app-feedback-field [field]="form.get('function')"></app-feedback-field>
    </div>

    <div class="form-group">
      <label class="required" for="jobTitle">{{ 'form.labels.job_title' | translate }}</label>
      <input placeholder="" class="form-control w-100" formControlName="job_title" id="jobTitle" type="text">
      <app-feedback-field [field]="form.get('job_title')"></app-feedback-field>
    </div>

    <!-- Location -->
    <div formGroupName="location" class="mt-3">
      <div class="form-group ">
        <label for="inputLocation">{{ 'form.labels.location' | translate }}</label>
        <input placeholder="" class="form-control w-100" formControlName="name" id="inputLocation" type="text">
        <p class="validation-feedback" *ngIf="this.form?.controls?.location.get('name').errors?.maxlength">{{ 'form.feedback.maxlength' | translate : {value:50} }}</p>

      </div>

      <!-- Checkbox: use location URL -->
      <div class="form-group ">
        <div class="form-check mb-0">
          <input class="form-check-input" id="checkboxUseLocationUrl"
                type="checkbox" formControlName="locationLink">
          <label class="form-check-label"
                for="checkboxUseLocationUrl">{{ 'modals.edit_hcp.include_location_url' | translate }}</label>
        </div>
      </div>

      <!-- Location URL -->
      <div  class="form-group " *ngIf="form.get('location').get('locationLink').value">
        <label for="inputLocationUrl">{{ 'form.labels.location_url' | translate }}</label>
        <input placeholder="" class="form-control w-100" formControlName="url"
              id="inputLocationUrl"
              type="text">

      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-secondary" id="add-hcp-modal-cancel">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-primary m-start-2" id="add-hcp-modal-submit">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
