<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/settings']" class="text-secondary font-weight-bold" id="settings-ooo-data-back" *ngIf="!backButtonData">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_settings' | translate }}</span>
        </a>

        <a class="" id="settings-ooo-detail-action-back-to-tasks" [routerLink]="backButtonData?.url" class="text-secondary font-weight-bold" *ngIf="backButtonData">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
          <span class="align-middle">{{ backButtonData?.titleKey | translate }}</span>
        </a>
      </p>
      <h1>{{ 'pages.default.settings.overview.ooo' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="ooo_enabled" [(ngModel)]="ooo.enabled" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="ooo_enabled" [innerHtml]="( 'pages.default.settings.ooo.enable_ooo' | translate )"></label>
      </div>

      <div class="row" *ngIf="ooo.enabled">
        <div class="col-3">
          <div class="form-group styled required floating-label">
            <input type="text" id="inputStartDate" class="form-control w-100" formControlName="start_date"  required bsDatepicker >
            <label for="inputStartDate" class="required">{{ 'pages.default.settings.ooo.start_date' | translate }}</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <timepicker id="inputStartTime" class="with-label" formControlName="start_time" [showMeridian]="!time_24_hours" [showSpinners]="false" [mousewheel]="false"></timepicker>
            <label for="inputStartTime" class="required">{{ 'pages.default.settings.ooo.start_time' | translate }}</label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="ooo.enabled">
        <div class="col-3">
          <div class="form-group styled required floating-label">
            <input type="text" id="inputEndDate" class="form-control w-100" formControlName="end_date" required bsDatepicker>
            <label for="inputEndDate" class="required">{{ 'pages.default.settings.ooo.end_date' | translate }}</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <timepicker id="inputEndTime" class="with-label" formControlName="end_time" [showMeridian]="!time_24_hours" [showSpinners]="false" [mousewheel]="false"></timepicker>
            <label for="inputEndTime" class="required">{{ 'pages.default.settings.ooo.end_time' | translate }}</label>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-6">
          <p id="feedback_dates_difference" class="validation-feedback general-feedback" *ngIf="form?.errors?.difference">{{ 'pages.default.settings.ooo.difference_error' | translate }}</p>
        </div>
      </div>

      <!-- task delegation -->
      <div *ngIf="ooo.enabled">
        <div class="pb-3 pb-lg-4"></div>
        <div class="heading-block mb-2">
          <div class="block-wrapper overflow-hidden">
            <h2 class="text-truncate">{{ 'pages.default.settings.ooo.delegate_tasks' | translate }}</h2>
          </div>
        </div>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="ooo_task_delegation_enabled" [(ngModel)]="ooo.task_delegate_enabled" [ngModelOptions]="{standalone: true}" (change)="oooTaskDelegationChanged()">
          <label class="form-check-label" for="ooo_task_delegation_enabled" [innerHtml]="( 'pages.default.settings.ooo.enable_task_delegation' | translate )"></label>
        </div>

        <div class="row" *ngIf="ooo.task_delegate_enabled">
          <div class="col-4">
            <div class="form-group required">

              <app-select
                [placeholder]="('pages.default.settings.ooo.delegate_to' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="generalSearchHcpsList"
                [searchFn]="customSearchFn"
                [loading]="isLoadingHcps"
                formControlName="task_delegate"
                (search)="searchHcpListEvent.next($event)"
                (open)="searchHCPList()"
                [asFilter]="false"
                [showUserDetails]="true"
                [showCheckboxes]="false"
              ></app-select>
              <app-feedback-field [field]="form.get('task_delegate')"></app-feedback-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="layout-footer-pusher" *ngIf="!isLoading"></div>
<div class="layout-footer" *ngIf="!isLoading">
  <div class="footer-content">
    <div class="container">
      <div class="m-start-auto">
        <button  class="btn btn-sm btn-secondary m-start-3" (click)="save()" [ngClass]="{'loader': isPerformingAction}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isPerformingAction"></em></span>
          <span>{{ 'action.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>


