<div class="heading-block border mb-3 help-center-faq-list" *ngIf="!compact">
  <div class="block-wrapper overflow-hidden">
    <h6 class="text-truncate">{{ helpcenterSection?.title }}</h6>
    <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
  </div>

  <button class="btn block-action m-start-auto" (click)="goToSectionOverview(helpcenterSection)"
    *ngIf="helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow">
    <span [inlineSVG]="'./assets/svg/arrow-right-24.svg'" class="icon rtl-mirrored-inline-svg"></span>
  </button>
</div>

<div *ngIf="compact">
  <h6>{{ helpcenterSection?.title }}</h6>
  <p class="mb-4">{{ helpcenterSection?.subtitle }}</p>
</div>

<div class="faq-blocks">
  <div class="items">
    <app-faq-block
      *ngFor="let category of helpcenterSection?.faqs?.slice(0, helpcenterSection.maxCategoriesToShow)"
      [category]="category"
      [section]="helpcenterSection"
      (goToFaq)="onGoToFaq($event)"
    />
  </div>
</div>

<div class="text-end" *ngIf="(helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow)">
  <button (click)="goToSectionOverview(helpcenterSection)" class="btn btn-link m-start-auto">
    {{ 'pages.default.help_center.show_all' | translate }}
  </button>
</div>
