<div class="is-loading row" *ngIf="isLoading">
  <app-page-loader class="col-12 align-self-center"></app-page-loader>
</div>

<div class="care-module" *ngIf="!isLoading">
  <p class="fw-semibold">{{ 'modals.add_patient_wizard.select_pathway' | translate }}</p>

  <!-- 2-5 care-modules === buttons -->
  <div class="care-modules" *ngIf="showCareModuleBtns()">
    <ul>
      <li *ngFor="let careModule of careModules">
        <button class="single-item-block item-clickable w-100 text-truncate mb-1" (click)="selectCareModule(careModule)" [ngClass]="{'active': careModule?.uid === selectedCareModule?.uid}">
          <div class="block-wrapper">
            <p class="label mb-0">{{ careModule.translationKey | translate }}</p>
          </div>

          <div class="block-btn-wrapper">
            <div class="block-action btn btn-tertiary btn-icon">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
            </div>
          </div>
        </button>
      </li>
    </ul>
  </div>

  <!-- >5 care-modules === select -->
  <div class="care-modules" *ngIf="!showCareModuleBtns()">
    <div class="form-group">
      <app-select
        [label]="('modals.add_patient.treatment' | translate)"
        [searchable]="true"
        [clearable]="false"
        [hideSelected]="false"
        [multiple]="false"
        [items]="careModules"
        [(ngModel)]="selectedCareModule"
        (change)="selectCareModule(selectedCareModule)"
      >
      </app-select>
    </div>
  </div>
</div>
