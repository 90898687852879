<div class="modal-header">
  <h6 class="h7">{{ 'modals.stop_pathway_modal.stop_pathway' | translate }}</h6>

  <button type="button" id="stop-pathway-form-close" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">

  <div class="participant-item mb-3">
    <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

    <div class="meta">
      <p class="title text-truncate">{{ patient?.getFullName() || '...' }}</p>
      <p class="description text-truncate" *ngIf="patient">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{ patient?.age() |
        translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</p>
    </div>
  </div>

  <div *ngIf="!patientPathways?.length && !isLoading">
    <p class="text-muted">{{ 'modals.stop_pathway_modal.no_available_pathways' | translate }}</p>
  </div>

  <form [formGroup]="form" *ngIf="patientPathways?.length" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <app-select
      [label]="('modals.stop_pathway_modal.pathway' | translate)"
      [clearable]="false"
      [searchable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="patientPathways"
      [bindValue]="'uid'"
      formControlName="pathway"
      [asFilter]="false"
      [showCheckboxes]="false"
      [showUserDetails]="false"
      [readonly]="readonly"
      >
      </app-select>

      <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
    </div>

    <hr/>

    <div class="form-group required">
      <app-select
      [label]="('modals.stop_pathway_modal.reason' | translate)"
      [clearable]="false"
      [searchable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="reasons"
      [bindValue]="'key'"
      [bindLabel]="'name'"
      formControlName="reason_code"
      [asFilter]="false"
      [showCheckboxes]="false"
      [showUserDetails]="false"
      >
      </app-select>
      <app-feedback-field [field]="form.get('reason')"></app-feedback-field>
    </div>

    <div class="form-group styled required floating-label" *ngIf="form.get('reason_code').value === 'OTHER'">
      <div class="d-flex justify-content-between mb-1">
        <label for="reason" class="required">{{ 'modals.stop_pathway_modal.specify_reason' | translate }}</label>

        <p class="extra-small text-muted m-0" *ngIf="!(validationVisible && !form?.controls?.reason?.value)">
          {{( form?.controls?.reason?.value?.length || '0' ) | translateNumber }}/{{reasonMaxLength | translateNumber}}
        </p>
      </div>

      <textarea autosize id="reason" class="form-control w-100" formControlName="reason" [placeholder]="'modals.stop_pathway_modal.specify_reason' | translate" required [maxlength]="reasonMaxLength"></textarea>

      <p class="validation-feedback">{{ 'modals.stop_pathway_modal.specify_reason' | translate }}</p>
    </div>
  </form>

  <div class="info-box info-box-warning mt-5">
    <em class="info-box-icon" [inlineSVG]="'./assets/svg/warning-24.svg'"></em>
    <p>{{ 'modals.stop_pathway_modal.info' | translate }}</p>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="stop-pathway-form-cancel" (click)="onHandleClose()">{{ 'action.cancel' | translate}}</button>

    <button class="btn btn-primary m-start-2" id="stop-pathway-form-submit" [ngClass]="{'loader': isSaving}" (click)="handleSubmit()" [disabled]="!form.valid">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'modals.stop_pathway_modal.stop_pathway' | translate }}</span>
    </button>

  </div>
</div>
