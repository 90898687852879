<div class="modal-header">
  <h6 class="h7" *ngIf="type === 'NEW'">{{ 'modals.appointment.add_new_appointment' | translate }}</h6>
  <h6 class="h7" *ngIf="type === 'EDIT'">{{ 'modals.appointment.edit' | translate }}</h6>

  <button type="button" class="btn-close" id="appointment-form-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    @if(type === 'NEW') {
      <div class="form-group required">
        <app-select
          [label]="('modals.appointment.patient' | translate)"
          placeholder="Select"
          [hideSelected]="false"
          [multiple]="false"
          [clearable]="true"
          [searchable]="true"
          [items]="patients"
          [searchFn]="customSearchFn"
          [asFilter]="false"
          [showCheckboxes]="false"
          [showUserDetails]="true"
          [loading]="isLoadingPatients"
          (search)="searchPatientLastNameEvent.next($event)"
          (change)="patientSelected($event)"
          formControlName="patient"
        ></app-select>

        <app-feedback-field [field]="form.get('patient')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          [label]="'modals.appointment.form.pathways' | translate"
          placeholder="Select"
          [hideSelected]="false"
          [multiple]="false"
          [clearable]="false"
          [searchable]="false"
          [items]="pathways"
          [searchFn]="customSearchFn"
          [asFilter]="false"
          [showCheckboxes]="false"
          [showUserDetails]="true"
          [loading]="isLoadingPathways"
          (change)="pathwaySelected($event)"
          formControlName="pathway"
        ></app-select>

        <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
      </div>
    }

    @if(type === 'EDIT') {
      <div class="form-group required">
        <label for="inputPatient">{{ 'modals.appointment.patient' | translate }}</label>
        <input type="text" id="inputPatient" class="form-control w-100" formControlName="patient" required disabled>
      </div>
    }

    <hr class="medium"/>

    <div class="form-group required">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputTitle" class="required">{{ 'modals.appointment.form.title' | translate }}</label>

        <p class="extra-small text-muted m-0" *ngIf="!(validationVisible && !form?.controls?.title?.value)">
          {{( (form?.controls?.title?.value?.length || '0') | translateNumber )}}/{{titleMaxLength | translateNumber}}
        </p>
      </div>

      <input type="text" id="inputTitle" class="form-control w-100" formControlName="title" [maxlength]="titleMaxLength" placeholder="">

      <app-feedback-field [field]="form.get('title')"></app-feedback-field>

      <p class="text-danger extra-small mt-1 mb-4">{{ 'modals.appointment.form.medical_date' | translate }}</p>
    </div>

    <div class="row align-items-end">
      <div class="col-6">
        <div class="form-group form-group-calendar required">
          <label for="inputDate" class="required">{{ 'modals.appointment.form.date' | translate }}</label>

          <input type="text" id="inputDate" class="form-control w-100" formControlName="start_date" required
            bsDatepicker>
        </div>
      </div>

      <div class="col-6">
        <div class="form-control shadow-none">
          <div class="form-check mb-0">
            <input type="checkbox" class="form-check-input" id="check-full-day" formControlName="full_day">

            <label class="form-check-label" for="check-full-day">
              {{ 'modals.appointment.form.full_day' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" *ngIf="!form.get('full_day')?.value">
      <div class="col-6">
        <div class="form-group">
          <label for="inputStartTime" [ngClass]="{'required': !form.get('full_day')?.value}">
            {{ 'modals.appointment.form.start_time' | translate }}
          </label>

          <timepicker id="inputStartTime" formControlName="start_time" [mousewheel]="false"
            [showMeridian]="!time_24_hours" [showSpinners]="false" dir="ltr"></timepicker>

        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="inputEndTime" [ngClass]="{'required': !form.get('full_day')?.value}">
            {{ 'modals.appointment.form.end_time' | translate }}
          </label>

          <timepicker id="inputEndTime" formControlName="end_time" dir="ltr"
            [showMeridian]="!time_24_hours" [showSpinners]="false" [mousewheel]="false"></timepicker>
        </div>
      </div>
    </div>

    <p id="feedback_time_after" class="validation-feedback general-feedback" *ngIf="form.get('end_time')?.errors?.after">
      {{ 'modals.appointment.time_validator' | translate }}
    </p>

    <div class="form-group mt-3">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputDescription">{{ 'modals.appointment.form.description' | translate }}</label>

        <p class="extra-small text-muted m-0"
           *ngIf="!(validationVisible && !form?.controls?.description?.value)">
          {{( (form?.controls?.description?.value?.length || '0') | translateNumber )}}
          /
          {{ descriptionMaxLength | translateNumber }}
        </p>
      </div>

      <textarea appTextareaAutoresize id="inputDescription" class="form-control w-100" formControlName="description" placeholder=""
        [maxlength]="descriptionMaxLength"></textarea>

      <app-feedback-field [field]="form.get('description')"></app-feedback-field>

      <p class="text-danger extra-small mt-1">{{ 'modals.appointment.form.medical_date' | translate }}</p>
    </div>

    <hr class="medium">

    <p><strong>{{ 'modals.appointment.form.attendees' | translate }}</strong></p>

    <div class="form-group">
      <label for="hcpselect">{{ 'modals.appointment.form.search_role_or_hcp' | translate }}</label>
      <ng-select
        #hcpselect
        class="users-select"
        id="hcpselect"
        [items]="hcps"
        [searchFn]="customSearchFn"
        [readonly]="hcps.length < 1"
        (change)="hcpSelected($event)"
      >

        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item">{{ item?.getFullName() }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="wrapper" *ngIf="item">
            <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

            <div class="user-info">
              <p class="title">{{ item?.getFullName() }}</p>
              <p class="sub-text">{{ item?.job_title }}</p>
            </div>

            <div class="status small text-muted m-start-2" *ngIf="isHcpSelected(item)">
              <span>{{ 'modals.appointment.form.already_added' | translate }}</span>
            </div>

            <div class="status btn btn-tertiary m-start-2" *ngIf="!isHcpSelected(item)">
              <span class="icon" [inlineSVG]="'./assets/svg/add-24.svg'"></span>
              <span>{{ 'modals.appointment.form.add' | translate }}</span>
            </div>

          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="participant-item mt-3" *ngIf="appointment?.patient_invitee">
      <app-avatar [profilePicture]="appointment?.patient_invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ appointment?.patient_invitee?.getFullName() }}</p>
        <p class="description">{{ 'modals.appointment.form.patient' | translate }}</p>
      </div>
      <div class="text-muted text-align-end">{{ 'modals.appointment.form.patient_not_removed' | translate }}</div>
    </div>

    <div class="participant-item" *ngFor="let invitee of appointment?.invitees">
      <app-avatar [profilePicture]="invitee?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title">{{ invitee?.getFullName() }}</p>
        <p class="description">{{ invitee?.job_title }}</p>
      </div>

      <button type="button" class="btn btn-tertiary" id="appointment-form-remove-hcp" (click)="removeHcp($event, invitee)">
        <span class="icon" [inlineSVG]="'./assets/svg/close-24.svg'"></span>
        <span>{{ 'modals.appointment.form.remove' | translate }}</span>
      </button>
    </div>

    <hr class="medium" />

    <p><strong>{{ 'modals.appointment.form.location' | translate }}</strong></p>

    <div class="form-group">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputLocation">{{ 'modals.appointment.form.location' | translate }}</label>
        <p class="extra-small text-muted m-0" *ngIf="!(validationVisible && !form?.controls?.location?.value)">
          {{ (form?.controls?.location?.value?.length || '0') | translateNumber }}/{{ locationMaxLength | translateNumber }}
        </p>
      </div>

      <input type="text" id="inputLocation" class="form-control w-100" formControlName="location" [maxlength]="locationMaxLength" placeholder="">
      <app-feedback-field [field]="form.get('location')"></app-feedback-field>
    </div>

    <div class="form-check mt-1">
      <input type="checkbox" class="form-check-input" id="check_link" [(ngModel)]="linkAvailable" [ngModelOptions]="{standalone: true}">
      <label class="form-check-label" for="check_link">
        {{ 'modals.appointment.form.include_link' | translate }}
      </label>
    </div>

    <div class="form-group styled  floating-label" *ngIf="linkAvailable">
      <label for="inputLink">{{ 'modals.appointment.form.link' | translate }}</label>
      <input type="text" id="inputLink" class="form-control w-100" formControlName="link" placeholder="">
      <app-feedback-field [field]="form.get('link')"></app-feedback-field>
    </div>

  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">

    <button type="button" class="btn btn-lg btn-secondary" id="appointment-form-cancel" (click)="handleCancel()">
      {{ 'action.cancel' | translate }}
    </button>

    <button type="submit" class="btn btn-lg btn-primary m-start-2" id="appointment-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
