import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormField } from '../../models/form-field';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicInputComponent),
      multi: true,
    },
  ],
})
export class DynamicInputComponent implements ControlValueAccessor {
  @Input() field: FormField;
  @Input() formControl: FormControl;

  public value: any;

  public datePickerConfig: any = {
    withTimepicker: true
  };
  public onChange: any = () => {
  };
  public onTouched: any = () => {
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onValueChange(e) {
    this.value = e;
    this.onChange(e);
  }
  
  onMultiValueChange(value: string) {
    if (!this.value) {
      this.value = [];
    }

    this.value = this.field.multiValueChange(value, this.value);

    this.onChange(this.value);
  }
}
