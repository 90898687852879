<div class="row align-items-center justify-content-between mb-2" *ngIf="patients?.length > 0">
  <div class="col-auto">
    <h5 id="table-ref">{{ 'pages.default.analytics.pathways-overview.patient-list.title' | translate}}</h5>
  </div>

  <div class="col-auto">
    <div class="filters-group">
      <!--
      <a href="" class="btn-link text-secondary m-end-3">Export all...</a>
      <a href="" class="btn-link text-secondary m-end-3">Choose columns...</a>
      -->

      <div class="btn-group text-dropdown text-dropdown-sm d-none" dropdown>
        <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
          <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

          <span *ngIf="sorting == 'last_name,asc'">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E;</span>
          <span *ngIf="sorting == 'last_name,desc'">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E;</span>
          <span *ngIf="sorting == 'pathway_started_at,asc'">{{ 'form.labels.pathway_started_at_asc' | translate }}&#x200E;</span>
          <span *ngIf="sorting == 'pathway_started_at,desc'">{{ 'form.labels.pathway_started_at_desc' | translate }}&#x200E;</span>

          <span class="icon" [inlineSVG]="'./assets/svg/chevron-down-24.svg'"></span>
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name', 'asc')">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E; </a></li>
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name', 'desc')">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E; </a></li>
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'pathway_started_at', 'asc')">{{ 'form.labels.pathway_started_at_sort_asc' | translate }}&#x200E; </a></li>
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'pathway_started_at', 'desc')">{{ 'form.labels.pathway_started_at_sort_desc' | translate }}&#x200E; </a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="list-group" aria-describedby="table-ref" *ngIf="patients?.length > 0">
  <div class="list-group-item list-group-header p-end-5">
    <div class="row align-items-center w-100">
      <div class="col-4">{{ 'pages.default.analytics.pathways-overview.patient-list.table.patient' | translate}}</div>
      <div class="col-2">{{ 'pages.default.analytics.pathways-overview.patient-list.table.start_date' | translate}}</div>
      <div class="col-2">{{ 'pages.default.analytics.pathways-overview.patient-list.table.current_phase' | translate}}</div>
      <div class="col-2" *ngFor="let measure of patients[0].measures">
        {{ measure.label.key | translate}}
      </div>
    </div>
  </div>

  <a class="list-group-item clickable" *ngFor="let patient of patients" href="" [routerLink]="['/patient/' + patient?.uid]" [queryParams]="{'back-to-url': '/analytics/pathways-overview'}">
    <div class="row w-100 align-items-center">
      <div class="col-4">
        <p class="fw-semibold e2e-patient-fullname">{{ patient.getFullName() }}</p>
      </div>
      <div class="col-2 e2e-patient-started_at">
        <p>{{ patient.pathway_started_at | timeZoneDate }}</p>
      </div>
      <div class="col-2 e2e-patient-phase">
        <p class="text-truncate">{{ patient.phase.translationKey | translate }}</p>
      </div>
      <div class="col-2 e2e-patient-measure" *ngFor="let measure of patient.measures">
        <p>
          {{ measure.value}}
          <ng-container *ngIf="measure.unit_of_value">
            {{ measure.unit_of_value?.key | translate }}
          </ng-container>
        </p>
      </div>
    </div>

    <div class="btn btn-sm btn-tertiary btn-icon">
      <span [inlineSVG]="'./assets/svg/arrow-right-24.svg'" class="rtl-mirrored-inline-svg"></span>
    </div>
  </a>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <div class="m-end-auto" id="total-patients">{{ getLabelForTotalElements() }}</div>
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
