<div class="modal-header">
  <h6 class="h7">{{ 'modals.stop_pathway_confirm_modal.stop_pathway' | translate }}</h6>

  <button type="button" class="btn-close" id="stop-pathway-confirm-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <p>{{'modals.stop_pathway_confirm_modal.description' | translate}}</p>

  <p class="fw-semibold mb-1">{{'modals.stop_pathway_confirm_modal.about_to_stop_following_pathway' | translate}}</p>

  <div class="bg-light p-3 rounded-4 border">
    <div class="participant-item mb-2 bg-white">
      <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title text-truncate">{{ patient?.getFullName() || '...' }}</p>
        <p class="description text-truncate" *ngIf="patient">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{ patient?.age() |
          translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</p>
      </div>
    </div>

    <div class="pathway">
      <p class="extra-small text-muted mb-0">{{ 'modals.stop_pathway_confirm_modal.pathway' | translate}}</p>
      <p class="small fw-semibold text-truncate mb-0">{{pathway?.translationKey | translate}}</p>
    </div>

    <hr class="my-2">

    <div class="reason">
      <p class="extra-small text-muted mb-0">{{ 'modals.stop_pathway_confirm_modal.reason' | translate}}</p>
      <p class="small fw-semibold mb-0">{{reason_code?.name}}</p>

      <p class="extra-small text-muted mb-0 mt-2" *ngIf="reason">
        {{ 'modals.stop_pathway_confirm_modal.specified_reason' | translate}}
      </p>
      <p class="small fw-semibold mb-0">{{reason}}</p>
    </div>

  </div>

</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="stop-pathway-confirm-cancel" (click)="onHandleClose()">
      {{ 'modals.stop_pathway_confirm_modal.go_back' | translate}}
    </button>

    <button class="btn btn-danger m-start-2" id="stop-pathway-confirm-submit" [ngClass]="{'loader': isSaving}" (click)="handleSubmit()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'modals.stop_pathway_confirm_modal.stop_pathway_confirm' | translate }}</span>
    </button>

  </div>
</div>
