<div class="modal-header">
  <h6 class="h7">{{ 'modals.delete_note.title' | translate }}</h6>
  <button type="button" class="btn-close" id="delete-note-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <app-select
      id="select_reason"
      [placeholder]="('form.labels.reason' | translate)"
      [clearable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="reasonOptions"
      [bindValue]="'value'"

      [autoSortOnLabel]="true"
      [selectedItemsOnTop]="false"

      formControlName="reason"

      [asFilter]="false"
      [showCheckboxes]="false"
    ></app-select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="delete-note-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-danger m-start-2" id="delete-note-modal-delete" (click)="handleDelete()" [ngClass]="{'loader': isLoading}" [disabled]="!form.valid">
      <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'modals.delete_note.delete_note' | translate }}</span>
    </button>
  </div>
</div>
