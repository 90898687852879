<div class="modal-header">
  <h6 class="h7">{{ 'modals.add_pathway_modal.add_pathway_modal' | translate }}</h6>

  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">




  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div *ngIf="!care_modules.length && !isLoading">
    <p class="text-muted">{{ 'modals.add_pathway_modal.no_available_pathways' | translate }}</p>
  </div>

  <form [formGroup]="form" *ngIf="care_modules.length && !isLoading">
    <div class="form-group styled required">
      <app-select
        [label]="('modals.add_pathway_modal.pathway' | translate)"
        [clearable]="false"
        [searchable]="false"
        [hideSelected]="false"
        [multiple]="false"
        [items]="care_modules"
        [bindValue]="'value'"
        formControlName="care_module"
        [asFilter]="false"
        [showCheckboxes]="false"
        [showUserDetails]="false"
        (change)="careModuleSelectedInForm($event)"
      ></app-select>
      <app-feedback-field [field]="form.get('care_module')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select
        [label]="('modals.add_pathway_modal.clinical_lead' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hcpList"
        [searchFn]="customSearchFn"
        (search)="searchHcpListEvent.next($event)"
        (close)="searchHcpList()"
        [bindValue]="'value'"
        formControlName="clinical_lead"
        [asFilter]="false"
        [showCheckboxes]="false"
        [showUserDetails]="true"
      ></app-select>
      <app-feedback-field [field]="form.get('clinical_lead')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select
        [label]="('modals.add_pathway_modal.case_manager' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hcpList"
        [searchFn]="customSearchFn"
        (search)="searchHcpListEvent.next($event)"
        (close)="searchHcpList()"
        [bindValue]="'value'"
        formControlName="case_manager"
        [asFilter]="false"
        [showCheckboxes]="false"
        [showUserDetails]="true"
      ></app-select>

      <app-feedback-field [field]="form.get('case_manager')"></app-feedback-field>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-cl-cm-form-cancel" (click)="onHandleClose()">
      {{ 'action.cancel' | translate}}
    </button>

    <button class="btn btn-primary m-start-2" id="edit-cl-cm-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()" [disabled]="!form.valid">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'modals.add_pathway_modal.add_pathway' | translate }}</span>
    </button>
  </div>
</div>
