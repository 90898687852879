<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.administration.overview.administration' | translate }}</h5>
    </div>
  </div>
</div>

<div class="container" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<div class="body-content" *ngIf="!isLoading">
  <div class="container">

    <h6 class="text-truncate mb-3">{{ 'pages.default.administration.overview.patients' | translate }}</h6>

    <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-patients"
      [routerLink]="['/administration/staff/patients']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/participants-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.manage_patients' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.manage_patients_description' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </div>

    <div class="pb-3 pb-lg-4"></div>

    <div *ngIf="hasClinicalCareModules">
      <h6 class="text-truncate mb-3">{{ 'pages.default.administration.overview.staff' | translate }}</h6>

      <div [routerLink]="['/administration/staff/hcps']" class="single-item-block item-clickable mb-2"
        id="admin-overview-manage-hcps">
        <div class="block-icon">
          <span [inlineSVG]="'./assets/svg/stethoscope-outline-24.svg'" class="icon"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_hcps' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_hcps_description' | translate }}</p>
        </div>
        <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </div>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-mdts"
        [routerLink]="['/administration/staff/mdts']">
        <div class="block-icon">
          <span class="icon" [inlineSVG]="'./assets/svg/participants-outline-more-24.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_mdts' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_mdts_description' | translate }}</p>
        </div>
        <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </div>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-tasks"
        [routerLink]="['/administration/tasks']">
        <div class="block-icon">
          <span class="icon" [inlineSVG]="'./assets/svg/checklist-24.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_tasks' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_tasks_description' | translate }}</p>
        </div>
        <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </div>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-care-modules"
        [routerLink]="['/administration/care-modules']">
        <div class="block-icon">
          <span class="icon" [inlineSVG]="'./assets/svg/ehr-outline-24.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_care_modules' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_care_modules_description' | translate }}</p>
        </div>
        <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </div>
        </div>
      </div>

      <div class="pb-3 pb-lg-4"></div>
    </div>

    <h6 class="text-truncate mb-3">{{ 'pages.default.administration.overview.patient_onboarding' | translate }}</h6>

    <div class="single-item-block item-clickable mb-2" id="admin-overview-view-codes"
      [routerLink]="['/administration/patient/onboarding-codes']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/privacy-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.view_codes' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.view_codes_description' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-requests"
      [routerLink]="['/administration/patient/onboarding-requests']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/success-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper w-100">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div>
            <p class="label">{{ 'pages.default.administration.overview.manage_requests' | translate }}</p>
            <p class="value">{{ 'pages.default.administration.overview.manage_requests_description' | translate }}</p>
          </div>

          @if(pendingRequests > 0) {
            <div class="block-wrapper no-flex">
              <div class="status-badge info">
                {{ pendingRequests }} {{ 'pages.default.administration.overview.manage_requests_pending' | translate }}
              </div>
            </div>
          }

        </div>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </div>

    <div class="pb-3 pb-lg-4"></div>

    <!-- d-none ->  temp hidden -->
    <h6 class="text-truncate mb-3 d-none">{{ 'pages.default.administration.overview.templates' | translate }}</h6>

    <!-- d-none ->  temp hidden -->
    <div class="d-none single-item-block item-clickable mb-2" id="admin-overview-manage-appointments"
      [routerLink]="['/administration/templates/appointments']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/calendar-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.manage_appointments' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.manage_appointments_description' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </div>

    <!-- d-none ->  temp hidden -->
    <div class="d-none single-item-block item-clickable mb-2" id="admin-overview-manage-messages"
      [routerLink]="['/administration/templates/messages']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/chat-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.manage_messages' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.manage_messages_description' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </div>

  </div>
</div>
