<div class="wizard modal-content-add-patient-wizard">
  <div class="modal-header">
    <h6 class="h7">{{ 'modals.add_patient_wizard.add_patient' | translate }}</h6>

    <button type="button" class="btn btn-close" id="add-patient-modal-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="bsModalRef.hide()">
    </button>
  </div>

  <div class="modal-body w-100">
    @if (isLoading) {
      <div class="is-loading">
        <app-page-loader></app-page-loader>
      </div>

    } @else {
      <!-- CARE-MODULE SELECT -->
        <!-- CARE-MODULE SELECT -->
        <!-- CARE-MODULE SELECT -->
      <div class="pathway-step" *ngIf="currentStep === stepsEnum.CAREMODULE">
        <app-pathway-step (careModulesLoaded)="careModulesLoaded()" [(careModules)]="careModules"
                          [(selectedCareModule)]="selectedCareModule"
                          [(selectedCareModuleWasUpdated)]="selectedCareModuleWasUpdated"
                          (careModuleSelected)="careModuleSelected()"></app-pathway-step>
      </div>

      <!-- PATIENT SELECT -->
        <!-- PATIENT SELECT -->
        <!-- PATIENT SELECT -->
      <div class="patient-step" *ngIf="currentStep === stepsEnum.PATIENT">
        <app-patient-step [(patient)]="patient" (patientValid)="patientValid($event)"></app-patient-step>
      </div>

      <!-- REGION SELECT -->
        <!-- REGION SELECT -->
        <!-- REGION SELECT -->
      <div class="language-step" *ngIf="currentStep === stepsEnum.REGION">
        <app-region-step [(country)]="country" [(language)]="language"></app-region-step>
      </div>

      <!-- HOSPITAL TEAM SELECT -->
        <!-- HOSPITAL TEAM SELECT -->
        <!-- HOSPITAL TEAM SELECT -->
      <div class="hospital-team-step" *ngIf="currentStep === stepsEnum.HOSPITALTEAM">
        <app-hospital-team-step [(selectedCareModule)]="selectedCareModule"
                                [(selectedCareModuleWasUpdated)]="selectedCareModuleWasUpdated"></app-hospital-team-step>
      </div>

      <!-- OVERVIEW -->
        <!-- OVERVIEW -->
        <!-- OVERVIEW -->

      <div class="overview-step" *ngIf="currentStep === stepsEnum.OVERVIEW">
        <app-overview-step [selectedCareModule]="selectedCareModule" [patient]="patient" [language]="language"
                           [country]="country"></app-overview-step>
      </div>
    }
  </div>

  <div class="modal-footer" *ngIf="!isLoading || showTimeLine || showPreviousBtn() || showNextBtn() || currentStep === stepsEnum.OVERVIEW">
    <div class="d-flex align-items-center justify-content-between w-100 m-0">
      <p class="mb-0" *ngIf="showTimeLine">
        {{ 'modals.add_patient_wizard.step' | translate }}
        {{ getStepIndex() }} {{ 'modals.add_patient_wizard.of' | translate }} {{ steps.length }}
      </p>

      <div class="m-start-auto">
        <button *ngIf="showPreviousBtn()" class="btn btn-secondary m-end-2" id="add-patient-modal-previous"
                (click)="onHandleStep('backward')">
          {{ 'modals.add_patient_wizard.previous_step' | translate }}
        </button>

        <button *ngIf="showNextBtn()" class="btn btn-primary" id="add-patient-modal-next"
                (click)="onHandleStep('forward')" [disabled]="nextStepIsDisabled()">
          {{ 'modals.add_patient_wizard.next_step' | translate }}
        </button>

        <button *ngIf="currentStep === stepsEnum.OVERVIEW" class="btn btn-primary" id="add-patient-modal-confirm"
                (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}">
          <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'modals.add_patient_wizard.confirm' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
