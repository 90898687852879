<div class="layout-header-pusher header-tabs"></div>

<div class="layout-header header-tabs">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.tasks.tasks' | translate }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [asClassicTabs]="true" [items]="pageTabItems"
              (onTabChange)="onTabChangeHandler($event)"></app-page-tabs>
    </div>
  </div>
</div>

<div class="inline-page-notification" *ngIf="!isLoading && ooo?.currentlyEnabled() && ooo.task_delegate_enabled">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'./assets/svg/building-outline-24.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.tasks.delegation_active' | translate }}</p>
        <p>{{ 'pages.default.tasks.currently_delegated_to' | translate }} <strong>{{ ooo.task_delegate.getFullName()
            }}</strong> until <strong>{{ ooo.end_date | timeZoneDate:dateFormat?.format }}</strong></p>
      </div>
      <div class="col-auto">
        <a href="" class="text-icon-link text-info" routerLink="/settings/ooo"
          [queryParams]="{'back-to-url': router.url}">
          <span class="label">{{ 'pages.default.tasks.out_of_office_button' | translate }}</span>
          <span class="icon-end">
            <em [inlineSVG]="'./assets/svg/arrow-right-24.svg'" class="rtl-mirrored-inline-svg"></em>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container pb-4">
    <div class="row justify-content-between align-items-end">
      <div class="col col-auto">

        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="filter-select-patient"
              class=""
              [selectClass]="'ng-select-sm'"
              [label]="('pages.default.tasks.select_patient' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="filter_patients"
              [searchFn]="customSearchFn"
              [(ngModel)]="filters.patient"
              (ngModelChange)="onFilterChange()"
              (search)="patientLastNameSearch.next($event)"
              [asFilter]="false"
              [showUserDetails]="true"
              [showCheckboxes]="false">
            </app-select>
          </div>

          <div class="form-group">
            <app-select
              id="filter-select-care-module"
              class=""
              [selectClass]="'ng-select-sm'"
              [label]="('pages.default.tasks.select_care_module' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="filter_careModules"
              [(ngModel)]="filters.careModule"
              (ngModelChange)="onFilterChange()"
              [bindValue]="'uid'"
              [asFilter]="false"
              [showCheckboxes]="true">
            </app-select>
          </div>

          <div *ngIf="filtersAreSet" class="divider"></div>

          <button (click)="actionResetFilters()" *ngIf="filtersAreSet" class="btn btn-link"
                  type="button" id="admin-patients-reset-filters">{{ 'action.reset' | translate }}</button>
        </div>

      </div>
    </div>
  </div>


  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <!-- MESSAGES -->
  <!-- MESSAGES -->
  <!-- MESSAGES -->
  <div class="container" *ngIf="!isLoading && currentContext === 'MESSAGES'">
    <div class="empty-state my-5 my-lg-7" *ngIf="!messages || messages.length <= 0">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.tasks.no_updates_yet' | translate }}</h6>
    </div>

    <ng-container *ngIf="messages?.length > 0">
      <div class="block-checkbox pb-1">
        <div class="form-check">
          <input (change)="onToggleAllCheckbox()" class="form-check-input"
                  id="checkbox_all"
                  [checked]="selectedAll"
                  type="checkbox">
          <label class="form-check-label" for="checkbox_all">{{ 'general.select_all' | translate}}</label>
        </div>
      </div>
    </ng-container>

    <div *ngFor="let message of messages; let index = index;">
      <div class="single-item-block block-compact mb-2" [ngClass]="{'block-selected': selectedMessagesMap.get(message)}"
        [id]="message?.testingID">
        <!-- (click)="openPathwayMessage($event, message)" -->

        <div class="block-checkbox">
          <div class="form-check">
            <input (change)="onToggleCheckbox(message)" class="form-check-input" id="checkbox_item_A{{index}}"
              type="checkbox" [checked]="selectedMessagesMap.get(message)">
            <label class="form-check-label" for="checkbox_item_A{{index}}"></label>
          </div>
        </div>

        <div class="row d-flex align-items-center w-100">
          <div class="col-4">
            <div class="block-wrapper py-2">
              <a href="" class="label text-truncate e2e-task-title" (click)='openPathwayMessage($event, message)'>
                {{ message.translationKey | translate }}
              </a>
              <p class="value text-truncate">{{ message?.care_module?.translationKey | translate }}</p>
            </div>
          </div>
          <div class="col-4">
            <div class="block-wrapper">
              <a href="" class="user-compact" id="message-go-to-patient-detail" (click)="goToPatientDetail($event, message?.patient?.uid, message?.patient_pathway_id)">
                <div class="avatar"
                  [style.backgroundImage]="'url(' + (message?.patient?.profile_picture?.url | secure | async) + ')'"
                  [ngClass]="{'empty': !message?.patient?.profile_picture}"></div>

                <div class="user-compact-info">
                  <p class="title text-truncate">{{ message?.patient.getFullName() }}</p>
                  <p>{{ message?.patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{
                    message?.patient?.age() | translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col-2">
            <div class="block-wrapper">
              <p class="extra-small value text-truncate">{{ 'pages.default.tasks.send_at' | translate }}</p>
              <p class="small text-truncate fw-semibold text-dark">{{ message?.send_at | timeZoneDate }}</p>
            </div>
          </div>
          <div class="col-2">
            <div class="block-wrapper" *ngIf="!message?.isRead">
              <p class="status-badge badge-sm success">
                <span class="icon" [inlineSVG]="'./assets/svg/dot-24.svg'"></span>
                <span class="align-middle">{{ 'pages.default.tasks.new' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="block-btn-wrapper">
          <button class="block-action btn btn-lg btn-tertiary btn-icon" (click)='openPathwayMessage($event, message)'>
            <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </button>
        </div>
      </div>
    </div>
  </div>



  <!-- END MESSAGES -->
  <!-- END MESSAGES -->
  <!-- END MESSAGES -->



  <!-- TASKS -->
  <!-- TASKS -->
  <!-- TASKS -->
  <div class="container" *ngIf="!isLoading && currentContext === 'TASKS'">
    <div class="empty-state my-5 my-lg-7" *ngIf="!tasks || tasks.length <= 0">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.tasks.no_tasks_yet' | translate }}</h6>
    </div>

    <div *ngFor="let task of tasks; let index = index;" [attr.data-priority]="task?.priority">
      <a href="" class="single-item-block block-compact item-clickable mb-2"
        [id]="'task-'+ task?.type + '-' + index" (click)='openUserTask($event, task)'>

        <div class="row d-flex align-items-center justify-content-between w-100">
          <div class="col-5">
            <div class="block-wrapper">
              <p class="label text-truncate">{{ task.translationKeyTitle | translate | evalTranslation }}</p>
              <p class="value text-truncate">{{ task.care_module?.translationKey | translate | evalTranslation }}</p>
            </div>
          </div>

          <div class="col-4">
            <div class="block-wrapper">
              <a href="" class="user-compact" id="task-go-to-patient-detail" (click)="goToPatientDetail($event, task?.patient?.uid)">
                <div class="avatar"
                  [style.backgroundImage]="'url(' + (task.patient?.profile_picture?.url | secure | async) + ')'"
                  [ngClass]="{'empty': !task.patient?.profile_picture}"></div>

                <div class="user-compact-info">
                  <p class="title text-truncate">{{ task.patient.getFullName() }}</p>
                  <p class="text-truncate">{{ task.patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{ task.patient?.age() |
                    translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</p>
                </div>
              </a>
            </div>

          </div>

          <div class="col-2 mx-auto">
            <div class="block-wrapper">
              <p class="extra-small value text-truncate">{{ 'pages.default.administration.tasks.date' | translate }}</p>
              <p class="small text-truncate font-weight-semibold text-dark">{{ task.created_at | timeZoneDate }}</p>
            </div>
          </div>
        </div>

        <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </div>
        </div>
      </a>
    </div>

  </div>
</div>

<!-- END TASKS -->
<!-- END TASKS -->
<!-- END TASKS -->

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage"
            (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize"
            (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10,20]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentContext === 'MESSAGES' && selectedMessageUids?.length">
  <div class="layout-footer h-auto">
    <div class="bulk-actions-bar">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          @if(selectedMessageUids.length === 1) {
          <p class="fw-semibold mb-0">{{selectedMessageUids.length}} {{ 'pages.default.tasks.updates_selected_singular' |
            translate }}</p>
          } @else {
          <p class="fw-semibold mb-0">{{selectedMessageUids.length}} {{ 'pages.default.tasks.updates_selected' | translate }}</p>
          }

          <div class="button-group d-flex">
            <button class="btn btn-secondary" [ngClass]="{'disabled': isLoadingTasks}"
              (click)="markSelectionUnread()">
              {{ 'pages.default.tasks.mark_as_unread' | translate }}
            </button>

            <button class="btn btn-primary m-start-1" [ngClass]="{'disabled': isLoadingTasks}"
              (click)="markSelectionRead()">
              {{ 'pages.default.tasks.mark_as_read' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
