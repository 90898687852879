<div class="modal-header">
  <h6 class="h7">{{ pathwayMessage?.translationKey | translate }}</h6>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
</div>

<div class="modal-body pb-0">
  <div *ngIf="isLoading">
    <app-page-loader [padding]="'30px 0'"></app-page-loader>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="participant-item mb-2" *ngIf="pathwayMessage?.patient">
      <div class="avatar" [style.backgroundImage]="'url(' + (pathwayMessage?.patient?.profile_picture?.url | secure | async) + ')'" [ngClass]="{'empty': !pathwayMessage?.patient?.profile_picture}"></div>

      <div class="meta">
        <p class="title text-truncate">{{ pathwayMessage?.patient.getFullName() }}</p>
        <p class="description text-truncate" *ngIf="pathwayMessage?.care_module">{{ pathwayMessage?.care_module?.translationKey | translate }}</p>
      </div>
    </div>

    <ng-container *ngIf="pathwayMessage?.message_content">
      <ng-container *ngFor="let text of pathwayMessage?.message_content">
        <div [innerHtml]="text"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!pathwayMessage?.message_content">
      <div class="info-box info-box-danger mb-2">
        <em class="info-box-icon" [inlineSVG]="'./assets/svg/alert-16.svg'"></em>
        <p class="info-box-title m-0">{{ 'error.general' | translate }}</p>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-primary" (click)="handleClose()">Close</button>
  </div>
</div>
