<ul class="icon-steps">
  <li *ngFor="let state of states" [ngClass]="{'active': isStateActiveOrDone(state)}">

    <div *ngIf="state === onboardingState.CONSENTS">
      <div class="head">
        <span class="icon" [inlineSVG]="'./assets/svg/information-outline-24.svg'"></span>
        <span class="label">{{ 'components.onboarding_icon_steps.consents' | translate }}</span>
      </div>

      <ul class="children" *ngIf="currentState === onboardingState.CONSENTS">
        <li *ngFor="let consent of consents" [ngClass]="{'active':consent.handled || consent==currentConsent}">{{
          consent.title ?? 'Unknown' }}</li>
      </ul>
    </div>

    <div *ngIf="state === onboardingState.PROFILE">
      <div class="head">
        <span class="icon" [inlineSVG]="'./assets/svg/account-outline-24.svg'"></span>
        <span class="label">{{ 'components.onboarding_icon_steps.your_details' | translate }}</span>
      </div>
    </div>

    <div *ngIf="state === onboardingState.EMAIL_VERIFICATION">
      <div class="head">
        <span class="icon" [inlineSVG]="'./assets/svg/envelope-outline-24.svg'"></span>
        <span class="label">{{ 'components.onboarding_icon_steps.email_verification' | translate }}</span>
      </div>
    </div>

    <div *ngIf="state === onboardingState.PASSWORD">
      <div class="head">
        <span class="icon" [inlineSVG]="'./assets/svg/locked-outline-16.svg'"></span>
        <span class="label">{{ 'components.onboarding_icon_steps.your_password' | translate }}</span>
      </div>
    </div>

    <div *ngIf="state === onboardingState.APPROVAL">
      <div class="head">
        <span class="icon" [inlineSVG]="'./assets/svg/checkmark-24.svg'"></span>
        <span class="label">{{ 'components.onboarding_icon_steps.approval' | translate }}</span>
      </div>
    </div>

  </li>
</ul>
