<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_conversation_participants.edit_participants' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-conversation-participants-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div class="py-3" *ngIf="isLoading">
    <div class="spinner spinner-sm my-0 mx-auto" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></div>
  </div>

  <div *ngIf="!isLoading">
    <div *ngIf="conversation.conversation_type === 'PATIENT_CONVERSATION'">
      <div class="d-flex align-items-end justify-content-between mb-2">
        <p class="m-0 fw-semibold lead">{{ 'modals.start_new_convo.participants' | translate }}</p>
        <a href="" class="text-link fw-semibold no-border"
          id="edit-conversation-participants-modal-select-everyone" (click)="selectEveryone($event)">
          {{ 'modals.edit_conversation_participants.select_everyone' | translate }}
        </a>
      </div>

      <div class="mb-3" *ngIf="currentPathway?.clinical_lead?.uid !== currentHcp.uid">
        <p class="caption extra-small m-0 mb-1">{{ 'modals.edit_conversation_participants.clinical_lead' | translate }}</p>

        <button class="participant-item clickable"
          [ngClass]="{'selected': selectedHcps[currentPathway?.clinical_lead?.uid]}"
          (click)="togglePatientHcp(currentPathway?.clinical_lead)">
          <app-avatar [profilePicture]="currentPathway?.clinical_lead.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ currentPathway?.clinical_lead?.getFullName() }}</p>
            <p class="description">{{ currentPathway?.clinical_lead?.job_title }}</p>
          </div>
          <div class="checkbox" *ngIf="currentPathway?.clinical_lead?.uid !== currentHcp.uid">
            <div class="form-check">
              <input type="checkbox" class="form-check-input"
                id="check_participant_{{ currentPathway?.clinical_lead?.uid }}"
                [(ngModel)]="selectedHcps[currentPathway?.clinical_lead?.uid]" [ngModelOptions]="{standalone: true}">
              <label class="form-check-label"
                for="check_participant_{{ currentPathway?.clinical_lead?.uid }}"></label>
            </div>
          </div>
        </button>
      </div>

      <div class="mb-3" *ngIf="currentPathway?.case_manager?.uid !== currentHcp.uid">
        <p class="caption extra-small m-0 mb-1">{{ 'modals.edit_conversation_participants.case_manager' | translate }}</p>

        <button class="participant-item clickable mb-3"
          [ngClass]="{'selected': selectedHcps[currentPathway?.case_manager?.uid]}"
          (click)="togglePatientHcp(currentPathway?.case_manager);">
          <app-avatar [profilePicture]="currentPathway?.case_manager.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ currentPathway?.case_manager?.getFullName() }}</p>
            <p class="description">{{ currentPathway?.case_manager?.job_title }}</p>
          </div>
          <div class="checkbox" *ngIf="currentPathway?.case_manager?.uid !== currentHcp.uid">
            <div class="form-check">
              <input type="checkbox" class="form-check-input"
                id="check_participant_{{ currentPathway?.case_manager?.uid }}"
                [(ngModel)]="selectedHcps[currentPathway?.case_manager?.uid]" [ngModelOptions]="{standalone: true}">
              <label class="form-check-label"
                for="check_participant_{{ currentPathway?.case_manager?.uid }}"></label>
            </div>
          </div>
        </button>
      </div>

      <div *ngFor="let mdt of mdts" class="mb-3">

        <div class="d-flex align-items-end justify-content-between mb-1">
          <p class="caption extra-small m-0">{{ mdt.name }}</p>
          <a href="" class="text-link fw-semibold no-border" (click)="selectAllFromMdt($event, mdt)">
            {{ 'modals.edit_conversation_participants.select_all_from_team' | translate }}
          </a>
        </div>

        <button class="participant-item clickable" *ngFor="let hcp of mdt?.hcps"
          [ngClass]="{'selected': selectedHcps[hcp?.uid]}" (click)="toggleMdtHcp(hcp);">
          <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ hcp?.getFullName() }}</p>
            <p class="description">{{ hcp?.job_title }}</p>
          </div>
          <div class="checkbox">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="check_participant_{{ hcp?.uid }}"
                [(ngModel)]="selectedHcps [hcp?.uid]" [ngModelOptions]="{standalone: true}">
              <label class="form-check-label" for="check_participant_{{ hcp?.uid }}"></label>
            </div>
          </div>
        </button>
      </div>

      <div *ngIf="otherHcps && otherHcps.length > 0">
        <div class="mb-3">
          <div class="row justify-content-between align-items-center mb-1">
            <div class="col-auto">
              <p class="small m-0">{{ 'modals.edit_conversation_participants.other' | translate }}</p>
            </div>
            <div class="col-auto">
              <p class="m-0">
                <button class="btn btn-link" (click)="selectAllFromGroup()">
                  {{ 'modals.edit_conversation_participants.select_all_from_group' | translate }}
                </button>
              </p>
            </div>
          </div>

          <button class="participant-item clickable" *ngFor="let hcp of otherHcps"
            [ngClass]="{'selected': selectedHcps[hcp?.uid]}" (click)="toggleMdtHcp(hcp);">
            <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
            <div class="meta">
              <p class="title">{{ hcp?.getFullName() }}</p>
              <p class="description">{{ hcp?.job_title }}</p>
            </div>
            <div class="checkbox">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="check_participant_{{ hcp?.uid }}"
                  [(ngModel)]="selectedHcps[hcp?.uid]" [ngModelOptions]="{standalone: true}">
                <label class="form-check-label" for="check_participant_{{ hcp?.uid }}"></label>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="conversation.conversation_type === 'HCP_CONVERSATION'">
      <ng-select
        class="users-select mb-2"
        [placeholder]="('modals.edit_conversation_participants.search_hcp' | translate)"
        [items]="hcpList"
        (search)="searchHcpListEvent.next($event)"
        [searchFn]="customSearchFn" bindLabel="first_name"
        (change)="onLosslessHcpSelect($event)"
        (open)="searchHCPList()"
        [loading]="isLoadingHcps"
        [(ngModel)]="losslessHcpSelectModel">
        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item">{{ item.getFullName() }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="wrapper" *ngIf="item">
            <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>
            <div class="user-info">
              <p class="title">{{ item?.getFullName() }}</p>
              <p class="sub-text">{{ item?.job_title }}</p>
            </div>
            <div class="status small text-muted m-start-2" *ngIf="isHcpSelected(item)">
              <span>{{ 'modals.edit_conversation_participants.already_added' | translate }}</span>
            </div>
            <div class="btn btn-tertiary m-start-2" *ngIf="!isHcpSelected(item)">
              <span class="icon" [inlineSVG]="'./assets/svg/add-24.svg'"></span>
              <span>{{ 'modals.edit_conversation_participants.add' | translate }}</span>
            </div>
          </div>
        </ng-template>
      </ng-select>

      <div class="participant-item" *ngFor="let hcp of losslessHcpSelection">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>

        <button class="btn btn-tertiary m-start-1"
          id="edit-conversation-participants-modal-remove-lossless-hcp-{{hcp.uid}}"
          (click)="removeLosslessHcp($event, hcp)" *ngIf="losslessHcpSelection.length > 1">
          <span class="icon" [inlineSVG]="'./assets/svg/close-24.svg'"></span>
          <span>{{ 'modals.edit_conversation_participants.remove' | translate }}</span>
        </button>
      </div>
      <div *ngIf="losslessHcpSelection.length === 1">
        <p class="extra-small text-muted mt-1">{{ 'modals.edit_conversation_participants.requirements' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-conversation-participants-modal-cancel" (click)="handleCancel()">{{
      'action.cancel' | translate }}</button>
    <button class="btn btn-primary m-start-2" id="edit-conversation-participants-modal-save"
      [ngClass]="{'loader': isSaving}" (click)="handleSave()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
