<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part">
      <h6>{{ 'pages.flow.forgot_password_reset.choose_new_pw' | translate }}</h6>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
        <div class="form-group styled">
          <label id="label_forgot_password_new_pw" for="input_forgot_password_new_pw">{{ 'pages.flow.forgot_password_reset.new_pw' | translate }}</label>
          <input id="input_forgot_password_new_pw" type="password" class="form-control" [placeholder]="('pages.flow.forgot_password_reset.new_pw'| translate)" formControlName="new_password" [ngClass]="{'ng-invalid-important': (form?.errors?.mismatch)}">
          <p id="feedback_forgot_password_pw_complexity_error" class="validation-feedback" *ngIf="form?.controls?.new_password?.errors?.password_policy">{{ 'form.feedback.pw_complexity_error' | translate }}</p>
          <p id="feedback_forgot_password_already_sed_error"class="validation-feedback" *ngIf="form?.controls?.new_password?.errors?.password_already_used">{{ 'form.feedback.pw_already_used' | translate:{value: policy?.history_size} }}</p>
        </div>

        <div class="form-group styled">
          <label id="label_forgot_password_confirm_new_pw" for="input_forgot_password_confirm_new_pw">{{ 'pages.flow.forgot_password_reset.confirm_new_pw' | translate }}</label>
          <input id="input_forgot_password_confirm_new_pw" type="password" class="form-control" [placeholder]="('pages.flow.forgot_password_reset.confirm_new_pw'|translate)" formControlName="new_password_verification" [ngClass]="{'ng-invalid-important': form?.errors?.mismatch}">
          <p id="feedback_forgot_password_pw_not_the_same" class="validation-feedback general-feedback" *ngIf="form?.errors?.mismatch">{{ 'pages.flow.forgot_password_reset.pw_not_the_same' | translate }}</p>
        </div>

        <button id="button_forgot_password_submit" type="submit" class="btn btn-primary mt-3 mt-xl-4" [ngClass]="{'loader': isLoading}">
          <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'pages.flow.forgot_password_reset.change_pw' | translate }}</span>
        </button>
      </form>
    </div>

    <div class="content-part">
      <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible" [passwordControl]="form?.controls?.new_password" ></app-password-policy-rules>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background d-flex flex-column">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="body-content mt-auto m-start-auto">
    <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
      <span class="icon" [inlineSVG]="'./assets/svg/assistant-24.svg'"></span>
      <span>{{ 'pages.flow.home.need_help' | translate }}</span>
    </button>
  </div>

  <div class="layout-background"></div>
</div>
