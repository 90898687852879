<div class="modal-header">
  <h6 class="h7">
    @switch (type) {
      @case ('add') {
        {{ 'pages.default.dashboard.assign_mdt_to_patient' | translate }}
      }
      @case ('edit') {
        {{ 'pages.default.dashboard.edit_mdt_for_patient' | translate }}
      }
      @case ('bulk-assign') {
        {{ 'modals.assign_mdt.bulk_edit_mdt_for_patient' | translate:{ nbOfPatients: this.patientList?.length } }}
      }
    }
  </h6>

  <button (click)="onHandleCancel()" aria-label="Close" class="btn-close" data-dismiss="modal"
          id="assign-mdt-modal-close" type="button"></button>
</div>

<div [ngClass]="{'validation-visible': validationVisible}" class="modal-body">

  @if (isLoading) {
    <app-page-loader></app-page-loader>
  } @else {
    <div class="form-group">
      <label for="mdt-hcp-select">{{ 'modals.edit_mdt.search_team_hcp' | translate }}</label>

      <ng-select
        class="users-select"
        id="mdt-hcp-select"
        [clearable]="true"
        [items]="allMdtsOrHcps"
        [searchFn]="customSearchFn"
        [(ngModel)]="mdtOrHcpSelect"
        (change)="onAddMdtOrHcp()"
        (search)="onSearch($event)"
      >

        <ng-template let-item="item" ng-label-tmp>
          <span *ngIf="item?.className === 'MedicalTeam'">{{ item?.name }}</span>
          <span *ngIf="item?.className === 'HealthCareProfessional'">{{ item?.getFullName() }}</span>
        </ng-template>

        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
          @if (item?.className === 'MedicalTeam') {
            <div class="wrapper" id="mdt-info">
              <div class="avatar">
                <span [inlineSVG]="'./assets/svg/community-24.svg'" class="icon"></span>
              </div>
              <div class="user-info">
                <p class="title">{{ item?.name }}</p>
                <p class="sub-text">{{ item?.hcpsAllNames }}</p>
              </div>
              <div *ngIf="isMdtSelected(item)" class="status small text-muted m-start-2">
                <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
              </div>
              <div *ngIf="!isMdtSelected(item)" class="btn btn-tertiary m-start-2">
                <span [inlineSVG]="'./assets/svg/add-24.svg'" class="icon"></span>
                <span>{{ 'action.add' | translate }}</span>
              </div>
            </div>

          } @else if (item?.className === 'HealthCareProfessional') {
            <div class="wrapper" id="hcp-info">
              <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

              <div class="user-info">
                <p class="title">{{ item?.getFullName() }}</p>
                <p class="sub-text">{{ item?.job_title }}</p>
              </div>
              <div *ngIf="isHcpSelected(item)" class="status small text-muted m-start-2">
                <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
              </div>
              <div *ngIf="!isHcpSelected(item)" class="btn btn-tertiary m-start-2">
                <span [inlineSVG]="'./assets/svg/add-24.svg'" class="icon"></span>
                <span>{{ 'action.add' | translate }}</span>
              </div>
            </div>
          }
        </ng-template>

      </ng-select>
    </div>

    <div *ngIf="patientMdt?.mdts?.length" class="mt-4">
      <p class="mb-2">{{ 'modals.assign_mdt.teams' | translate }}</p>

      <div *ngFor="let mdt of patientMdt.mdts" class="participant-item">
        <div class="avatar">
          <span [inlineSVG]="'./assets/svg/community-24.svg'" class="icon"></span>
        </div>

        <div class="meta">
          <p class="title">{{ mdt?.name }}</p>
          <p class="description">
          <span *ngFor="let hcp of mdt?.hcps; let i=index">
            <span>{{ hcp?.getFullName() }}</span>
            <span *ngIf="(i < (mdt?.hcps.length - 1))">, </span>
          </span>
          </p>
        </div>

        <button (click)="onRemoveMdt($event, mdt)" [id]="'assign-mdt-modal-remove-mdt-' + mdt.uid"
          class="btn btn-tertiary m-start-1">
          <span [inlineSVG]="'./assets/svg/close-24.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </button>
      </div>
    </div>

    <div *ngIf="patientMdt?.otherHcps?.length" class="mt-4">
      <p class="mb-2">{{ 'modals.assign_mdt.other_hcps' | translate }}</p>

      <div *ngFor="let hcp of patientMdt.otherHcps" class="participant-item">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>
        <a (click)="onRemoveHcp($event, hcp)"
          [id]="'assign-mdt-modal-remove-hcp-' + hcp.uid"
          class="btn btn-tertiary m-start-1"
          href="">
          <span [inlineSVG]="'./assets/svg/close-24.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </a>
      </div>
    </div>
  }
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button type="button" (click)="onHandleCancel()" class="btn btn-secondary" id="assign-mdt-modal-cancel">
      {{ 'action.cancel' | translate }}
    </button>

    <button type="button" (click)="onHandleAssign()" [disabled]="buttonDisabled()" [ngClass]="{'loader': isSaving}"
            class="btn btn-primary m-start-2" id="assign-mdt-modal-save">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
