<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part">
      <h6>{{ 'pages.flow.home.pls_sign_in' | translate }}</h6>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': validationVisible}"
            autocomplete="off">
        <div class="form-group">
          <label id="label_home_login_email_address"
                 for="input_home_login_email_address">{{ 'form.labels.email_address' | translate }}</label>
          <input id="input_home_login_email_address" type="email" class="form-control" formControlName="email"
                 [ngClass]="{'ng-invalid-important': credentialsIncorrect}">
          <app-feedback-field [field]="form.get('email')" *ngIf="hasInput()"></app-feedback-field>
        </div>

        <div class="form-group">
          <label id="label_home_login_password"
                 for="input_home_login_password">{{ 'form.labels.password' | translate }}</label>
          <input id="input_home_login_password" type="password" class="form-control" formControlName="password"
                 [ngClass]="{'ng-invalid-important': credentialsIncorrect}">
          <!--          TODO: Refactor to use app-feedback-field -->
          <!--          <app-feedback-field [field]="form.get('password')" *ngIf="hasInput()"/>-->

          <p id="feedback_home_login_email_or_pw_incorrect" class="validation-feedback general-feedback"
             *ngIf="credentialsIncorrect">{{ 'pages.flow.home.email_or_pw_incorrect' | translate }}</p>
          <p id="feedback_home_login_cannot_be_empty" class="validation-feedback general-feedback"
             *ngIf="!hasInput() && !credentialsIncorrect ">{{ 'pages.flow.home.cannot_be_empty' | translate }}</p>
          <p id="feedback_home_account_locked" class="validation-feedback general-feedback"
             *ngIf="accountLocked">{{ 'pages.flow.home.account_locked' | translate }}</p>
        </div>

        <p class="mt-3 mb-0">
          <a id="link_home_forgot_pw" [routerLink]="['forgot-password']"
            class="text-link">{{ 'pages.flow.home.forgot_pw' | translate }}</a>
        </p>

        <button id="button_home_login_submit" type="submit" class="btn btn-primary mt-3 mt-lg-4"
                [ngClass]="{'loader': isLoading}">
          <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'action.sign_in' | translate }}</span>
        </button>
      </form>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights [languageSelect]="true"></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background d-flex flex-column">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="body-content mt-auto m-start-auto">
    <button type="button" class="btn btn-light btn-rounded" (click)="openHelpCenter()">
      <span class="icon" [inlineSVG]="'./assets/svg/assistant-24.svg'"></span>
      <span>{{ 'pages.flow.home.need_help' | translate }}</span>
    </button>
  </div>

  <div class="layout-background"></div>
</div>
