<div class="dynamic-input">

  <!-- TYPE Boolean Checkbox -->
  @if (field.field_value.value_type === 'BOOLEAN' && !field.required) {
    <div class="form-group">
      <div class="form-check m-0">
        <input type="checkbox" class="form-check-input" [id]="field.id" [formControl]="formControl">

        <label class="form-check-label" [for]="field.id">
          {{ field.translationKey | translate | evalTranslation }}
        </label>
      </div>

      <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0"></app-feedback-field>
    </div>
  }

  <!-- TYPE Boolean Radiobutton -->
  @if (field.field_value.value_type === 'BOOLEAN' && field.required) {
    <div class="form-group">
      <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid }">
        {{ field.translationKey | translate | evalTranslation }}
      </label>

      <div class="form-checks-inline">
        <div class="form-check">
          <input class="form-check-input" [id]="'yes_'+ field.id" type="radio" [formControl]="formControl"
                 [value]="true">

          <label class="form-check-label" [for]="'yes_'+field.id">{{ 'action.yes' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" [id]="'no_'+field.id" type="radio" [formControl]="formControl"
                 [value]="false">

          <label class="form-check-label" [for]="'no_'+field.id">{{ 'action.no' | translate }}</label>
        </div>
      </div>

      <app-feedback-field [field]="formControl" [meta]="field"
                          [extraClass]="'general-feedback mt-n2'"></app-feedback-field>
    </div>
  }

  <!-- TYPE Enum -->
  @if (field.field_value.value_type === 'ENUM') {
    <div class="form-group">

      <label [ngClass]="{'required' : field.required}">
        {{ field.translationKey | translate | evalTranslation }}
      </label>

      @if (field.field_value.multi_select_allowed) {
        <!-- MultiSelect-->
        <div class="mt-1"></div>

        @for (enum of field?.field_value.enum_values | sortBy: 'asc': 'order'; track enum.value) {
          <div class="form-check">
            <input class="form-check-input" [ngClass]="{'ng-invalid': formControl.invalid }" type="checkbox"
                   [id]="field.id + enum.value" [name]="field.id + enum.value"
                   [checked]="field?.enumChecked(enum.value, value)" (change)="onMultiValueChange(enum.value)"
                   [disabled]="formControl.disabled">

            <label class="form-check-label" [for]="field.id + enum.value">{{ enum.label | translate }}</label>
          </div>
        }

        <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0"/>
      } @else {
        <!-- Single Select-->
        <div [ngClass]="field.field_value.enum_values.length > 2 ? 'form-checks mt-1': 'form-checks-inline'">
          @for (enum of field.field_value.enum_values | sortBy: 'asc': 'order'; track enum.value) {
            <div class="form-check">
              <input class="form-check-input" type="radio" [id]="field.id + enum.key" [formControl]="formControl"
                     [name]="field.id" [value]="enum.value">

              <label class="form-check-label" [for]="field.id + enum.key">
                {{ enum.label | translate }}
              </label>
            </div>
          }
        </div>
      }

      <app-feedback-field [field]="formControl" [meta]="field" [extraClass]="'general-feedback'"
                          class="mt-n2"></app-feedback-field>

      @if (field.description) {
        <p class="text-muted small">
          {{ field?.description | translateHelp | translate }}
        </p>
      }

    </div>
  }

  <!-- TYPE Number -->
  @if (field.field_value.value_type === 'NUMBER') {
    <div class="form-group">
      <label [ngClass]="{'required' : field.required}" [for]="'num-'+field.id">
        {{ field.translationKey | translate | evalTranslation }}
      </label>

      <input placeholder="" class="form-control" [id]="'num-'+field.id" type="number" [name]="field.id"
             [formControl]="formControl">

      <app-feedback-field [field]="formControl" [meta]="field"></app-feedback-field>
    </div>
  }


  <!-- TYPE Decimal -->
  @if (field.field_value.value_type === 'DECIMAL_NUMBER') {
    <div class="form-group">
      <label [ngClass]="{'required' : field.required}" [for]="'field_'+field.id">
        {{ field.translationKey | translate | evalTranslation }}
      </label>

      <input placeholder="" class="form-control" type="number" [id]="'field_'+field.id" [name]="field.id"
             [formControl]="formControl">

      <app-feedback-field [field]="formControl" [meta]="field"></app-feedback-field>
    </div>
  }

  <!-- TYPE String -->
  @if (field.field_value.value_type === 'STRING') {
    <div class="form-group">
      <label [ngClass]="{'required' : field.required}" [for]="'field_'+field.id">
        {{ field.translationKey | translate | evalTranslation }}
      </label>

      <input placeholder="" class="form-control" type="text" [name]="field.id" [id]="'field_'+field.id"
             [formControl]="formControl">

      <app-feedback-field [field]="formControl" [meta]="field"></app-feedback-field>
    </div>
  }

  <!-- TYPE Date -->
  @if (field.field_value.value_type === 'DATE') {
    <div class="form-group form-group-calendar">
      <label [ngClass]="{'required' : field.required, 'invalid': !formControl?.valid}">
        {{ field?.translationKey | translate | evalTranslation }}
      </label>

      <app-date-input [formControl]="formControl" [name]="field.id"></app-date-input>

      <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0"/>
    </div>
  }

  <!-- TYPE Date-Time -->
  @if (field.field_value.value_type === 'DATE_TIME') {
    <div class="form-group">
      <label [ngClass]="{'required' : field.required, 'invalid': !formControl?.valid}">
        {{ field?.translationKey | translate | evalTranslation }}
      </label>

      <app-date-time-input [formControl]="formControl" [name]="field.id"></app-date-time-input>

      <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0"/>
    </div>
  }

  <!-- description for  Number,  Boolean, String and Date -->
  @if (field.field_value.value_type !== 'ENUM' && field.description) {
    <p class="text-muted small mt-1">
      {{ field?.description | translateHelp | translate }}
    </p>
  }
</div>
