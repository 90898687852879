<div class="modal-header">
  <h6 class="h7">
    {{ 'modals.mdt.edit_mdt' | translate }}
  </h6>

  <button type="button" class="btn-close" id="edit-mdt-modal-close" data-dismiss="modal" aria-label="Close"
          (click)="onHandleClose()">
  </button>
</div>

<div class="modal-body">

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group">
      <label for="inputMdtName">{{ 'form.labels.input_label_name_team' | translate }}</label>

      <input placeholder=""
             class="form-control w-100"
             formControlName="name"
             id="inputMdtName"
             required
             type="text">

      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>


    <div class="form-group">
      <label for="inputMdtHcps">{{ 'modals.mdt.edit_mdt_hcps' | translate }}</label>

      <ng-select
        (ngModelChange)="onAddLosslessHcp($event)"
        (search)="searchHcpListEvent.next($event)"
        [loading]="isLoadingHcps"
        (open)="searchHcpList()"
        [(ngModel)]="losslessHcpSelectModel"
        [items]="hcpSearchResults"
        [searchFn]="customSearchFn"
        [closeOnSelect]="false"
        class="users-select mb-2"
        id="inputMdtHcps"
        [ngModelOptions]="{standalone: true}"
      >
        <ng-template let-item="item" ng-label-tmp>
          <span *ngIf="item">{{ item.getFullName() }}</span>
        </ng-template>

        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
          <div *ngIf="item" class="wrapper">
            <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

            <div class="user-info">
              <p class="title">{{ item?.getFullName() }}</p>
              <p class="sub-text">{{ item?.job_title }}</p>
            </div>

            <div *ngIf="isHcpSelected(item)" class="status small text-muted m-start-2">
              <span>{{ 'modals.mdt.already_added' |  translate }}</span>
            </div>

            <div *ngIf="!isHcpSelected(item)" class="status btn btn-tertiary m-start-2">
              <span [inlineSVG]="'./assets/svg/add-24.svg'" class="icon"></span>
              <span>{{ 'action.add' | translate }}</span>
            </div>

          </div>
        </ng-template>
      </ng-select>

      <div *ngFor="let hcp of losslessHcpSelection" class="participant-item">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>

        <button (click)="onRemoveLosslessHcp($event, hcp)" class="status btn btn-tertiary"
                [id]="'edit-mdt-modal-remove-lossless-hcp-' + hcp.uid">
          <span [inlineSVG]="'./assets/svg/close-24.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </button>

      </div>

      <p *ngIf="!hcpsAreValid() && !validationVisible" class="small text-muted">
        {{ 'modals.mdt.no_team_members_added' |  translate }}
      </p>

      <p *ngIf="!hcpsAreValid() && validationVisible"
         class="text-danger small">{{ 'form.feedback.one_hcp_required' | translate }}
      </p>

      <p *ngIf="hcp_uid_error && validationVisible"
         class="text-danger small">{{ 'shared.business_error_keys.' + hcp_uid_error | translate }}
      </p>
    </div>

  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-mdt-modal-cancel" (click)="onHandleClose()">
      {{ 'action.cancel' | translate }}
    </button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-primary m-start-2"
            id="edit-mdt-modal-submit">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
