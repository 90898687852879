<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights [cookiesButton]="false" [languageSelect]="false"></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">
      <div class="text-content-max">
        <h4 class="mb-3" id="content_cookie_page_title">{{ policy?.title }}</h4>

        <div class="article-content full-width no-paddings">
          <div class="content-partial" [innerHtml]="description"></div>
        </div>
      </div>
    </div>
  </div>
</div>
