<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': validationVisible}">
      <p>
        <a id="link_forgot_password_back" [routerLink]="['/']" class="text-link">
          <span class="icon-start rtl-d-none" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
          <span class="icon-start d-none rtl-d-inline" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
          {{ 'pages.flow.forgot_password.back_to_home' | translate }}
        </a>
      </p>

      <h6>{{ 'pages.flow.forgot_password.forgot_pw' | translate }}</h6>

      <p class="small mb-3">{{ 'pages.flow.forgot_password.forgot_pw_text' | translate }}</p>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" class="form-group styled mb-1" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
        <label id="label_forgot_password_email_address" for="input_forgot_password_email_address">{{ 'form.labels.email_address' | translate }}</label>
        <input id="input_forgot_password_email_address" type="email" class="form-control w-100" [placeholder]="('form.labels.email_address'|translate)" formControlName="email" >
        <p id="feedback_forgot_password_email_invalid" class="validation-feedback" *ngIf="form?.controls['email']?.errors?.email || form?.controls['email']?.errors?.required">{{ 'pages.flow.forgot_password.email_invalid' | translate }}</p>
      </form>

      <button id="button_forgot_password_submit" type="submit" class="btn btn-primary mt-2 mt-lg-3" [ngClass]="{'loader': isLoading}" (click)="formSubmit()">
        <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
        <span>{{ 'pages.flow.forgot_password.send_instructions' | translate }}</span>
      </button>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background d-flex flex-column">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="body-content mt-auto m-start-auto">
    <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
      <span class="icon" [inlineSVG]="'./assets/svg/assistant-24.svg'"></span>
      <span>{{ 'pages.flow.home.need_help' | translate }}</span>
    </button>
  </div>

  <div class="layout-background"></div>
</div>
