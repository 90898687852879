import { Component, EventEmitter, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from './../../../../services/general.service';
import { DateFormat } from './../../../../models/date-format';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HcpService } from '../../../../services/hcp.service';
import { HealthCareProfessional } from '../../../../models/health-care-professional';
import { OooService } from '../../../../services/ooo.service';
import { Ooo } from '../../../../models/ooo';
import { DatesFieldsValidator } from '../../../../validators/dates-validator';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BackButtonData } from '../../../../models/back-button-data';
import moment, { Moment } from 'moment';
import 'moment/min/locales';
import { LocaleService } from '../../../../services/locale.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-settings-ooo-overview',
  templateUrl: './settings-ooo.component.html'
})
export class SettingsOooComponent implements OnInit {
  public form: UntypedFormGroup;
  public dateFormat: DateFormat;
  public time_24_hours: boolean;
  public timeZone: string;
  public validationVisible = false;
  public oooEnabled = false;
  public oooTaskDelegationEnabled = false;
  public generalSearchHcpsList: Array<HealthCareProfessional>;
  public hcpSelectModel: HealthCareProfessional;
  public ooo: Ooo = new Ooo();
  public backButtonData: BackButtonData;
  public isLoading: boolean = false;
  public isLoadingHcps: boolean = false;
  public isPerformingAction: boolean = false;
  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public dataService: DataService,
    public generalService: GeneralService,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public oooService: OooService,
    public hcpService: HcpService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public localeService: LocaleService,
    private readonly activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.formSetup();
    this.getOoo();
    this.timeZone = this.localeService.getLocalePreferences().locale.time_zone;

    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.handleQueryParams(queryParams);
    });

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHCPList(result);
    });
  }

  handleQueryParams(queryParams) {
    const backToUrl = queryParams['back-to-url'];

    if (backToUrl) {
      this.backButtonData = this.generalService.defineBackButton(backToUrl);
    }
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      start_date: [],
      start_time: [],
      end_date: [],
      end_time: [],
      task_delegate: []
    }, {
      validators: [DatesFieldsValidator.validate('start_date', 'end_date', 'start_time', 'end_time', 'difference')]
    });
  }

  oooTaskDelegationChanged() {
    if (this.ooo.task_delegate_enabled) {
      this.form.controls['task_delegate'].setValidators(Validators.required);
    } else {
      this.form.controls['task_delegate'].clearValidators();
      this.form.controls['task_delegate'].updateValueAndValidity();
    }
  }

  searchHCPList(event?) {
    let term = '';
    if (event && event['term'] && event['term'].length) {
      term = event['term'];
    } else {
      this.generalSearchHcpsList = [];
    }

    this.isLoadingHcps = true;

    this.hcpService.getPaged({ hcpLastName: term, status: 'ACTIVE' }).subscribe({
      next: response => {
        const currentHcpUid = this.hcpService.getCurrentStoredHcpUid();
        this.generalSearchHcpsList = response.items.filter(hcp => { return (hcp.uid !== currentHcpUid) });
      }, complete: () => this.isLoadingHcps = false
    });
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  save() {
    if (this.ooo.enabled && !this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isPerformingAction = true;

    const currentHcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.oooService.update(currentHcpUid, this.postableOoo()).subscribe(
      () => this.onUpdateOooSuccess(),
      () => this.onUpdateOooError()
    );
  }

  onUpdateOooSuccess() {
    this.isPerformingAction = false;
    this.toastrService.success(this.translate.instant('pages.default.settings.ooo.change_success'));
  }

  onUpdateOooError() {
    this.isPerformingAction = false;
  }

  getOoo() {
    this.isLoading = true;

    const currentHcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.oooService.getOutOfOffice(currentHcpUid).subscribe(result => {
      this.isLoading = false;
      this.ooo = result;

      if (this.ooo.start_date) {
        this.form.controls['start_date'].setValue(this.convertDate(this.ooo.start_date));
        this.form.controls['start_time'].setValue(this.convertDate(this.ooo.start_date));
      } else {
        this.form.controls['start_date'].setValue(new Date());
        this.form.controls['start_time'].setValue(new Date());
      }

      if (this.ooo.end_date) {
        this.form.controls['end_date'].setValue(this.convertDate(this.ooo.end_date));
        this.form.controls['end_time'].setValue(this.convertDate(this.ooo.end_date));
      } else {
        this.form.controls['end_date'].setValue(new Date(new Date().getTime() + 60 * 60 * 24 * 1000));
        this.form.controls['end_time'].setValue(new Date(new Date().getTime() + 60 * 60 * 24 * 1000));
      }

      this.form.controls['task_delegate'].setValue(this.ooo.task_delegate);

      this.oooTaskDelegationChanged();
    }, () => {
      this.isLoading = false;
    });
  }

  postableOoo() {
    const delegateUid = this.form.controls['task_delegate'].value ? this.form.controls['task_delegate'].value.uid : null;
    let startDate = this.combineDates(this.form.controls['start_date'].value, this.form.controls['start_time'].value);
    let endDate = this.combineDates(this.form.controls['end_date'].value, this.form.controls['end_time'].value);

    startDate = this.postableDate(startDate);
    endDate = this.postableDate(endDate);

    return {
      enabled: this.ooo.enabled,
      start_date: startDate,
      end_date: endDate,
      task_delegate_enabled: this.ooo.task_delegate_enabled,
      task_delegate_uid: delegateUid
    };
  }

  combineDates(date: Date, time: Date) {
    if (!date) {
      return;
    }

    return (new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes()));
  }

  convertDate(date) {
    if (!date || !this.timeZone) {
      return;
    }
    const dateString = moment(new Date(date)).tz(this.timeZone).format('YYYY/MM/DD HH:mm:ss');
    return new Date(dateString);
  }

  postableDate(date) {
    if (!date || !this.timeZone) {
      return;
    }
    return moment(date).tz(this.timeZone, true).toDate();
  }
}
