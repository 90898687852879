<div class="modal-header">
  <h6 class="h7">{{ 'modals.generate_onboarding_code.generate_new_code' | translate }}</h6>
  <button type="button" class="btn-close" id="generate-code-modal-cancel" data-dismiss="modal" aria-label="Close" (click)="handleCancel()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <div class="form-group">
      <app-select
        [placeholder]="('form.labels.pathway' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hospitalCodes"
        [bindValue]="'value'"

        formControlName="pathway"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
    </div>
  </form>

  <div class="mt-3" *ngIf="showNewCode">
    <p class="small text-muted m-0">{{ 'modals.generate_onboarding_code.registration_code' | translate }}</p>

    <div class="my-1">
      <span class="align-middle font-weight-semibold" id="generate-code-modal-code">{{ getCodeNotation() }}</span>

      <a href="" class="btn btn-tertiary m-start-1" id="generate-code-modal-copy-code" (click)="actionCopyCode($event)">
        <span [inlineSVG]="'./assets/svg/file-general-24.svg'"></span>
      </a>
    </div>

    <p class="small text-muted m-0" id="generate-code-modal-valid-until">
      @if(newTimedCode.end_at) {
        {{ 'pages.default.administration.onboarding_codes.valid_until' | translate }} {{ newTimedCode.end_at | timeZoneDate:dateFormat.format }}
      } @else {
        {{ 'pages.default.administration.onboarding_codes.no_expiry' | translate }}
      }
    </p>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button *ngIf="!showNewCode" class="btn btn-secondary" id="generate-code-modal-action-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button *ngIf="showNewCode" class="btn btn-primary" id="generate-code-modal-close" (click)="handleCancel()">{{ 'action.close' | translate }}</button>

    <button class="btn btn-primary m-start-2" id="generate-code-modal-generate" [ngClass]="{'loader': isLoading}" *ngIf="!showNewCode" (click)="handleGenerate()">
      <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.generate' | translate }}</span>
    </button>
  </div>
</div>
