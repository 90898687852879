<div class="breadcrumb">
  <ng-container *ngFor="let crumb of crumbs; let i = index">
    <a class="crumb-link" *ngIf="i !== (crumbs.length - 1)" [routerLink]="crumb.link" [queryParams]="crumb.queryParams">
      {{ crumb.label }}
    </a>

    <span class="crumb-label" *ngIf="i == (crumbs.length - 1)">
      {{ crumb.label }}
    </span>

    <span class="crumb-delimiter rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-right-24.svg'" *ngIf="i !== (crumbs.length - 1)"></span>
  </ng-container>
</div>
